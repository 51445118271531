<div 
    *ngIf="isToggledOn"
    [style.transform]="renderRotation()"
    [style.width.em]="element.width"
    [style.height.em]="getHeight() || getHeight()==0 ? getHeight() : element.height"
    [ngStyle]="getStyle()"
    [class.is-clickable]="element.isClickable"
    [class.is-animatable]="element.isAnimatable || element.isClickable"
    [class.is-revealed]="element.isOff"
    [style.pointer-events]="getPointerEvents()"
    (click)="onClick()"
>
    <div *ngFor="let contentElement of element.content">
        <element-render 
            [contentElement]="contentElement" 
            [questionState]="questionState"
            [isLocked]="isLocked"
            [contentElement]="contentElement"
            [questionPubSub]="questionPubSub"
            [allowSubtitles]="allowSubtitles"
            [allowTranscripts]="allowTranscripts"
            [allowAudioPlaybackSpeed]="allowAudioPlaybackSpeed"
            [allowVideoPlaybackSpeed]="allowVideoPlaybackSpeed"
        ></element-render>
    </div> 
</div>
