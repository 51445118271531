<div *ngIf="element.url" class="mouse-over" style="position:relative">

  <!-- <video controls crossorigin="anonymous">
    <source [src]="getVideoUrl()" [type]="element.fileType">
  </video> -->
  <!-- <plyr  plyrTitle="" 
        [plyrPlaysInline]="false" 
        [plyrOptions]="plyrOptions"
        [plyrSources]="getVideoSources()" 
        [plyrTracks]="getSubtitleTracks()" 
        plyrCrossOrigin="anonymous"
  ></plyr> -->
    <!-- <div plyr plyrTitle="" [plyrPlaysInline]="false" [plyrCrossOrigin]="true"
   [plyrSources]="getVideoSources()" [plyrTracks]="getSubtitleTracks()" (plyrInit)="player = $event"
  (plyrPlay)="played($event)"></div> -->
  <!-- <div style="font-weight:bold;" *ngIf="resetter && this.questionState && this.questionState[element.entryId] && element.maxViews">
    <tra-md [isCondensed]="true" slug="lbl_num_views_left" [props]="{NUM_REM_VIEWS: element.maxViews - questionState[element.entryId].numViews}"></tra-md>
  </div> -->
  
  <div style="display: flex; flex-direction: row; position: relative; width: fit-content; height: fit-content; padding: 0px;">
    <video 
      #videoPlayer
      [id]="'video'+element.entryId" 
      [autoplay]="hasStarted()" 
      (play)="playing()" 
      (timeupdate)="onTimeUpdate()" 
      (ended)="ended()" 
      (pause)="notPlaying()" 
      [controls]="!isLimitted" 
      (seeked)="onSeeking()"
      [width]="640 * getZoom()" 
      [height]="480 * getZoom()" 
      controlsList="nodownload nofullscreen" 
      (canplay)="canStartPlaying()"
      (loadedmetadata)="loadedMetaData()"
      disablePictureInPicture
      preload="auto"
      crossorigin="anonymous"
    >
      <source type="video/mp4" [src]="getVideoSources()[0].src">
      <track *ngIf="allowSubtitles"
             default
             kind="subtitles"
             [srclang]="getSubtitleTracks()[0].srclang"
             [src]="getSubtitleTracks()[0].src"
             type="text/vtt"
             [label]="getSubtitleTracks()[0].label">
    </video> 
    <!-- <div *ngIf="element.maxViews > 0 || element.isUnPausable" #videoTooltip (click)="dismissTooltip()" class="video-tool-tip" [class.tool-tip-show]="isTooltipVisible" [class.tool-tip-hide]="!isTooltipVisible">
      <ng-container *ngIf="element.maxViews > 0">
        <p><tra-md [isCondensed]="true" slug="video_tooltip_max_views" [props]="{MAX_VIEWS: element.maxViews}"></tra-md></p>
      </ng-container>
      <ng-container *ngIf="element.isUnPausable">
        <p><tra slug="video_tooltip_unpausable"></tra></p>
      </ng-container>
      <i style="color: #a9d0ff"><tra slug="video_tooltip_dismiss"></tra></i>
    </div> -->
  </div>
  
  <div *ngIf="isLimitted" style="position:absolute; position:absolute;left:0px; right:0px; top:0px; bottom:0px; display: flex; justify-content: center; align-items: center;">
    <!--<tra-md [isCondensed]="true" slug="lbl_num_views_left" [props]="{NUM_REM_VIEWS: element.maxViews - questionState[element.entryId].numViews}"></tra-md>-->
    <button *ngIf="!isPlaying" (click)="play()" style="font-size:4em;">
      <i class="fas fa-play-circle"></i>
    </button>
    
    <div class="on-mouse-over" >
      <div *ngIf="!element.isAlwaysShowingTime" class="video-info-display">
        <div *ngIf="resetter && this.questionState && this.questionState[element.entryId] && element.maxViews">
          <tra-md  [isCondensed]="true" slug="lbl_num_views_left" [props]="{NUM_REM_VIEWS: element.maxViews - questionState[element.entryId].numViews}"></tra-md>
        </div>
        <div>
            {{getSecRemainingStr()}} <tra slug="lbl_num_secs_remaining" ></tra>
        </div>
      </div>
      <div *ngIf="element.maxViews > 0 || element.isUnPausable" #videoTooltip (click)="dismissTooltip()" class="video-tool-tip" [class.tool-tip-show]="isTooltipVisible" [class.tool-tip-hide]="!isTooltipVisible">
        <ng-container *ngIf="element.maxViews > 0">
          <p><tra-md [isCondensed]="true" slug="video_tooltip_max_views" [props]="{MAX_VIEWS: element.maxViews}"></tra-md></p>
        </ng-container>
        <ng-container *ngIf="element.isUnPausable">
          <p><tra slug="video_tooltip_unpausable"></tra></p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLimitted && element.isAlwaysShowingTime" class="video-info-display" >
  <div>
    <tra-md *ngIf="resetter && this.questionState && this.questionState[element.entryId] && element.maxViews" [isCondensed]="true" slug="lbl_num_views_left" [props]="{NUM_REM_VIEWS: element.maxViews - questionState[element.entryId].numViews}"></tra-md>
  </div>
  <div>{{getSecRemainingStr()}} <tra slug="lbl_num_secs_remaining" ></tra></div>
</div><br>
<button *ngIf="element.allowFullScreen" (click)="fullScreen()">
  Full Screen
</button>
<div *ngIf="allowPlaybackSpeed">
  <tra-md [isCondensed]="true" slug="bc_authoring_view_playbackrate"></tra-md>
  <div class="control is-expanded">
    <div class="select">
      <select [(ngModel)]="playBackRate" (ngModelChange)="adjustRate()">
        <option *ngFor="let rate of playBackRates; let index = index" [value]="rate">
          {{rate}}
        </option>
      </select>
    </div>
  </div>  
</div>
<div *ngIf="allowTranscripts" class="transcript">
  <button *ngIf="transcriptUrl" (click)="showTranscript()" class="transcript-toggle">
    {{transcriptOn ? 'Hide Transcript' : 'Show Transcript'}}
  </button>
  <div [innerHtml]="transcript" *ngIf="transcriptOn && transcriptUrl" class="transcript-content">
</div>
  
</div>

