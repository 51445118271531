<div class="page-body">
  <div>
    <header
    [breadcrumbPath]="breadcrumb"
    ></header>
    <div class="page-content is-fullpage">

      <div  class="dashboard-cards-container">

        <menu-bar
          [menuTabs]="views"
          [tabIdInit]="selectedView"
          (change)="selectView($event)"
        ></menu-bar>

        <div *ngIf="!this.isLoaded && !this.isLoadFailed">
          Loading
        </div>
        <div *ngIf="this.isLoadFailed">
          You do not have the required roles to access this page.
        </div>

        <div [ngSwitch]="selectedView">
          <div *ngSwitchCase="TCView.EQAO_G9">

            <div class="notification is-danger" *ngIf="IS_COUNTS_DISABLED || IS_COUNTS_DISABLED">
              Some functionality on this page has been disabled while it undergoes maintenance.
            </div>

            <div class="card dashboard-card" *ngIf="!IS_COUNTS_DISABLED">
              <div class="image-holder">
                <img src="https://mathproficiencytest.ca/img/test-window-icon.png">
              </div>
              <div class="table-holder">
                <div class="lead">Assessment Windows</div>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Dates</th>
                    <th>Status</th>
                    <th>Include in Summaries</th>
                  </tr>
                  <tr *ngFor="let window of g9TestWindows">
                    <td>{{window.id}} ({{window.projectId}})</td>
                    <td>{{window.dateRange}}</td>
                    <td>{{window.status}}</td>
                    <td [ngSwitch]="!!checkActiveWindow(window)">
                      <button *ngSwitchCase="true" class="button is-small is-info">
                        Selected
                      </button>
                      <button *ngSwitchCase="false" class="button is-small" (click)="selectTestWindowFocus(window)">
                        Not Selected
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="card dashboard-card">
              <div class="image-holder">
                <img src="https://mathproficiencytest.ca/img/account-icon.png">
              </div>
              <div class="table-holder">


                <div class="lead">
                  <div>Summaries</div>
                  <div class="buttons">
                    <!-- <button class="button is-small">Export to Excel</button> -->
                  </div>
                </div>
                <div>
                  <!-- Click on the link to the boards and schools to further filter the d -->
                </div>
                <table>
                  <tr>
                    <th>Account Type</th>
                    <ng-container *ngIf="!IS_COUNTS_DISABLED">
                      <th *ngFor="let col of userGroupSummaryColumns">
                        <tra [slug]="col.caption"></tra>
                      </th>
                    </ng-container>
                  </tr>
                  <tr *ngFor="let userGroupType of userGroupTypes">
                    <td [ngSwitch]="userGroupType.caption" class="is-data-label">
                      <a *ngSwitchDefault [href]="getRawLink(userGroupType)" target="_blank">
                        <tra [slug]="userGroupType.caption"></tra>
                      </a>
                      <a *ngSwitchCase="'Schools'" routerLink="/en/test-ctrl/school">
                        <tra [slug]="userGroupType.caption"></tra>
                      </a>
                      <a *ngSwitchCase="'Boards'" routerLink="/en/test-ctrl/school-boards">
                        <tra [slug]="userGroupType.caption"></tra>
                      </a>
                      <a *ngSwitchCase="'Classes'" routerLink="/en/test-ctrl/school-classes">
                        <tra [slug]="userGroupType.caption"></tra>
                      </a>
                      <a *ngSwitchCase="'Teachers'" routerLink="/en/test-ctrl/school-teachers">
                        <tra [slug]="userGroupType.caption"></tra>
                      </a>
                      <a *ngSwitchCase="'Students'" routerLink="/en/test-ctrl/school-students">
                        <tra [slug]="userGroupType.caption"></tra>
                      </a>
                    </td>
                    <ng-container *ngIf="!IS_COUNTS_DISABLED">
                      <td *ngFor="let col of userGroupSummaryColumns" class="has-data">
                        <!-- {{getColValue(userGroupType.id, col.id)}} -->
                        <a [routerLink]="getColLink(userGroupType.id)" [queryParams]="{ order: col.id }">
                          {{getColValue(userGroupType.id, col.id)}}
                        </a>
                      </td>
                    </ng-container>
                  </tr>
                </table>

                <div *ngIf="false" style="margin-top:2em;">
                  <strong>Technical Readiness Tracker</strong>
                </div>
                <table *ngIf="false">
                  <tr>
                    <th>Tally</th>
                    <th>SchName</th>
                    <th>SchMident</th>
                    <th>PrincipalFirstName</th>
                    <th>PrincipalEmail</th>
                  </tr>
                  <tr *ngFor="let school of g9Summary.tech_readi" [class.is-strong]="school.tally==10">
                    <td>{{school.tally}}/10</td>
                    <td>{{school.SchName}}</td>
                    <td>{{school.SchMident}}</td>
                    <td>{{school.PrincipalFirstName}}</td>
                    <td>{{school.PrincipalEmail}}</td>
                  </tr>
                </table>

              </div>
            </div>

            <div class="card dashboard-card" *ngIf="!IS_DOWNLOADS_DISABLED">
              <div class="image-holder">
                <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/excel/1606094586078/excel.png">
              </div>
              <div class="table-holder">
                <div class="lead">Data Downloads</div>
                <div>
                  The links below can be used to download the relevant data. Data mapping is consistent with the codebook. The only data frames that are currently avaialble are the ones marked with the spreadsheet icon (<i style="color: #15aabf;" class="fas fa-file-excel"></i>).
                </div>
                <div>
                  <strong>Filter is set to the active test window.</strong>
                </div>
                <table>
                  <ng-container *ngFor="let section of codebookTable.sections">
                    <tr class="lead-row">
                      <td>{{section.id}}</td>
                      <td [ngSwitch]="!!section.link" class="lead-caption">
                        <span *ngSwitchCase="false">{{section.caption}}</span>
                        <a *ngSwitchCase="true" target="_blank" [href]="section.link">{{section.caption}}</a>
                      </td>
                      <td></td>
                    </tr>
                    <tr *ngFor="let dataFrame of section.dataFrames">
                      <td>{{section.id}}.{{dataFrame.id}}</td>
                      <td [ngSwitch]="!!dataFrame.isEnabled">
                        <a *ngSwitchCase="true" [href]="downloadDataFrame(dataFrame)" target="_blank">
                          <i style="color: #15aabf; margin-right:0.5em;" class="fas fa-file-excel"></i>
                          {{dataFrame.caption}}
                        </a>
                        <span *ngSwitchCase="false">
                          {{dataFrame.caption}}
                        </span>
                      </td>
                      <td>
                        {{dataFrame.description}}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>

          </div>

          <div *ngSwitchCase="TCView.EQAO_G10">

            <div class="notification is-danger" *ngIf="IS_COUNTS_DISABLED || IS_COUNTS_DISABLED">
              Some functionality on this page has been disabled while it undergoes maintenance.
            </div>

            <div class="card dashboard-card" *ngIf="!IS_COUNTS_DISABLED">
              <div class="image-holder">
                <img src="https://mathproficiencytest.ca/img/test-window-icon.png">
              </div>
              <div class="table-holder">
                <div class="lead">Assessment Windows</div>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Dates</th>
                    <th>Status</th>
                    <th>Include in Summaries</th>
                  </tr>
                  <tr *ngFor="let window of g10TestWindows">
                    <td>{{window.id}} ({{window.projectId}})</td>
                    <td>{{window.dateRange}}</td>
                    <td>{{window.status}}</td>
                    <td [ngSwitch]="!!checkActiveG10Window(window)">
                      <button *ngSwitchCase="true" class="button is-small is-info">
                        Selected
                      </button>
                      <button *ngSwitchCase="false" class="button is-small" (click)="selectG10TestWindowFocus(window)">
                        Not Selected
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="card dashboard-card" >
              <div class="image-holder">
                <img src="https://mathproficiencytest.ca/img/account-icon.png">
              </div>
              <div class="table-holder">


                <div class="lead">
                  <div>Summaries</div>
                  <div class="buttons">
                    <!-- <button class="button is-small">Export to Excel</button> -->
                  </div>
                </div>
                <div>
                  <!-- Click on the link to the boards and schools to further filter the d -->
                </div>
                <table>
                  <tr>
                    <th>Account Type</th>
                    <ng-container *ngIf="!IS_COUNTS_DISABLED">
                      <th *ngFor="let col of userGroupSummaryColumns">
                        <tra [slug]="col.caption"></tra>
                      </th>
                    </ng-container>
                  </tr>
                  <tr *ngFor="let userGroupType of userGroupTypes">
                    <td class="is-data-label">
                      <tra [slug]="userGroupType.caption"></tra>
                    </td>
                    <ng-container *ngIf="!IS_COUNTS_DISABLED">
                      <td *ngFor="let col of userGroupSummaryColumns" class="has-data">
                        {{getG10ColValue(userGroupType.id, col.id)}}
                        <!-- <a [routerLink]="getColLink(userGroupType.id)" [queryParams]="{ order: col.id }">
                          {{getG10ColValue(userGroupType.id, col.id)}}
                        </a> -->
                      </td>
                    </ng-container>
                  </tr>
                </table>

                <div *ngIf="false" style="margin-top:2em;">
                  <strong>Technical Readiness Tracker</strong>
                </div>
                <table *ngIf="false">
                  <tr>
                    <th>Tally</th>
                    <th>SchName</th>
                    <th>SchMident</th>
                    <th>PrincipalFirstName</th>
                    <th>PrincipalEmail</th>
                  </tr>
                  <tr *ngFor="let school of g9Summary.tech_readi" [class.is-strong]="school.tally==10">
                    <td>{{school.tally}}/10</td>
                    <td>{{school.SchName}}</td>
                    <td>{{school.SchMident}}</td>
                    <td>{{school.PrincipalFirstName}}</td>
                    <td>{{school.PrincipalEmail}}</td>
                  </tr>
                </table>

              </div>
            </div>

            <div class="card dashboard-card" *ngIf="!IS_DOWNLOADS_DISABLED"> 
              <div class="image-holder">
                <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/excel/1606094586078/excel.png">
              </div>
              <div class="table-holder">
                <div class="lead">Data Downloads</div>
                <div>
                  The links below can be used to download the relevant data. Data mapping is consistent with the codebook. The only data frames that are currently avaialble are the ones marked with the spreadsheet icon (<i style="color: #15aabf;" class="fas fa-file-excel"></i>).
                </div>
                <div>
                  <strong>Filter is set to the active test window.</strong>
                </div>
                <table>
                  <ng-container *ngFor="let section of codebookTable.sections">
                    <tr class="lead-row">
                      <td>{{section.id}}</td>
                      <td [ngSwitch]="!!section.link" class="lead-caption">
                        <span *ngSwitchCase="false">{{section.caption}}</span>
                        <a *ngSwitchCase="true" target="_blank" [href]="section.link">{{section.caption}}</a>
                      </td>
                      <td></td>
                    </tr>
                    <tr *ngFor="let dataFrame of section.dataFrames">
                      <td>{{section.id}}.{{dataFrame.id}}</td>
                      <td [ngSwitch]="!!dataFrame.isEnabled">
                        <a *ngSwitchCase="true" [href]="downloadG10DataFrame(dataFrame)" target="_blank">
                          <i style="color: #15aabf; margin-right:0.5em;" class="fas fa-file-excel"></i>
                          {{dataFrame.caption}}
                        </a>
                        <span *ngSwitchCase="false">
                          {{dataFrame.caption}}
                        </span>
                      </td>
                      <td>
                        {{dataFrame.description}}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>

          </div>

          <div *ngSwitchCase="TCView.EQAO_MPT">
            <div class="notification is-warning">
              Please use the <strong>mathproficiencytest.ca</strong> site to access this Test Controller dashboard.
            </div>
            <div *ngIf="this.isLoaded" >
              <div class="card dashboard-card">
                <div class="image-holder">
                  <img src="https://mathproficiencytest.ca/img/test-window-icon.png">
                </div>
                <div class="table-holder">
                  <h2> <tra slug="lbl_test_windows"></tra> </h2>
                  <div class="table-holder-container">
                    <table class="table is-bordered is-hoverable" style="min-width: 34em;">
                      <tr *ngFor="let tw of testWindowSummaries">
                        <td>
                          <div class="lead"> {{renderTwTitle(tw)}} </div>
                          <div> {{renderTwDateStart(tw)}} </div>
                          <div> {{renderTwDateEnd(tw)}} </div>
                        </td>
                        <td>
                          <div><a (click)="disabled()"> {{renderTwNumSessionsCr(tw)}} <tra slug="txt_test_sessions_created"></tra>  </a> </div>
                          <div><a (click)="disabled()"> {{renderOldUnclosed(tw)}} <tra slug="old and unclosed"></tra>  </a> </div>
                          <!-- <div><a (click)="disabled()"> {{renderTwNumSessionsAdm(tw)}} <tra slug="txt_test_sessions_adminstrd"></tra>  </a> </div> -->
                          <!-- <div><a (click)="disabled()"> {{renderTwNumResPend(tw)}} <tra slug="txt_results_pending_reles"></tra>  </a> </div> -->
                        </td>
                        <td style="width:6em;">
                          <a [routerLink]="getTestWindowRoute(tw.id)" class="button">
                            <tra slug="btn_manage"></tra>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="table-footer-spread">
                    <div></div>
                    <button (click)="createTestWindow()"  class="button is-success">
                      <tra slug="btn_create_test_window"></tra>
                    </button>
                  </div>
                </div>
              </div>

              <div class="card dashboard-card">
                <div class="image-holder">
                  <img src="https://mathproficiencytest.ca/img/account-icon.png">
                </div>
                <div class="table-holder">
                  <div class="flex-header-container">
                    <h2> <tra slug="title_accounts_roles"></tra> </h2>
                    <div class="flex-header-ctrl">
                      <label class="checkbox">
                        <input type="checkbox" [formControl]="showLast30days">
                        <span>Show last 30 days</span>
                      </label>
                      <button class="button is-info" (click)="refreshData()">refresh</button>
                    </div>
                  </div>

                  <div class="table-holder-container">
                    <table class="table is-bordered is-hoverable is-number-table" style="min-width: 34em;">
                      <tr>
                        <td></td>
                        <th><tra slug="lbl_table_registered"></tra></th>
                        <th *ngIf="showLast30days.value"><tra slug="lbl_table_last30"></tra></th>
                        <th><tra slug="lbl_table_pending_invitation"></tra></th>
                      </tr>
                      <tr>
                        <th class="is-left-aligned"><tra slug="lbl_table_test_applicants"></tra></th>
                        <td>{{renderAccountTally('num_test_applicants', 'r')}}</td>
                        <td *ngIf="showLast30days.value">{{renderAccountTally('num_test_applicants', 'l30')}}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="is-left-aligned"><tra slug="lbl_table_session_bookings"></tra></td>
                        <td>{{renderAccountTally('num_session_bookings', 'r')}}</td>
                        <td *ngIf="showLast30days.value">{{renderAccountTally('num_session_bookings', 'l30')}}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="is-left-aligned"><tra slug="lbl_table_session_waitlisters"></tra></td>
                        <td>{{renderAccountTally('num_session_waitlisters', 'r')}}</td>
                        <td *ngIf="showLast30days.value">{{renderAccountTally('num_session_waitlisters', 'l30')}}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="is-left-aligned"><tra slug="lbl_table_accom_req"></tra></td>
                        <td>{{renderAccountTally('num_accomm_pending', 'r')}}</td>
                        <td *ngIf="showLast30days.value">{{renderAccountTally('num_accomm_pending', 'l30')}}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th class="is-left-aligned"><tra slug="lbl_table_test_admin"></tra></th>
                        <td>{{renderAccountTally('num_institutions', 'r')}}</td>
                        <td *ngIf="showLast30days.value">{{renderAccountTally('num_institutions', 'l30')}}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="is-left-aligned"><tra slug="lbl_table_inst_mngr"></tra></td>
                        <td>{{renderAccountTally('num_inst_mngrs', 'r')}}</td>
                        <td *ngIf="showLast30days.value">{{renderAccountTally('num_inst_mngrs', 'l30')}}</td>
                        <td>{{renderAccountTally('num_inst_mngrs', 'p')}}</td>
                      </tr>
                      <tr>
                        <td class="is-left-aligned"><tra slug="lbl_table_accomm_coord"></tra></td>
                        <td>{{renderAccountTally('num_accomm_coords', 'r')}}</td>
                        <td *ngIf="showLast30days.value">{{renderAccountTally('num_accomm_coords', 'l30')}}</td>
                        <td>{{renderAccountTally('num_accomm_coords', 'p')}}</td>
                      </tr>
                      <tr>
                        <td class="is-left-aligned"><tra slug="lbl_table_invig"></tra></td>
                        <td>{{renderAccountTally('num_invigs', 'r')}}</td>
                        <td *ngIf="showLast30days.value">{{renderAccountTally('num_invigs', 'l30')}}</td>
                        <td>{{renderAccountTally('num_invigs', 'p')}}</td>
                      </tr>
                      <tr>
                        <th class="is-left-aligned"><tra slug="Certification Body"></tra></th>
                        <td>{{renderAccountTally('num_cert_bodys', 'r')}}</td>
                        <td class="is-minor-val"  *ngIf="showLast30days.value">{{renderAccountTally('num_cert_bodys', 'l30')}}</td>
                        <td class="is-minor-val">{{renderAccountTally('num_cert_bodys', 'p')}}</td>
                      </tr>
                      <tr>
                        <th class="is-left-aligned"><tra slug="Test Controller"></tra></th>
                        <td>{{renderAccountTally('num_test_ctrls', 'r')}}</td>
                        <td class="is-minor-val"  *ngIf="showLast30days.value">{{renderAccountTally('num_test_ctrls', 'l30')}}</td>
                        <td class="is-minor-val">{{renderAccountTally('num_test_ctrls', 'p')}}</td>
                      </tr>
                    </table>
                  </div>
                  <div  class="table-footer-spread">
                    <div class="hide-on-sml">&nbsp;</div>
                    <a [routerLink]="getAccountsRoute()" class="button is-success">
                      <tra slug="btn_manage_accounts"></tra>
                    </a>
                  </div>
                </div>
              </div>

              <div class="card dashboard-card" *ngIf="isTestCtrlReportingExplorerEnabled()">
                <div class="image-holder">
                  <img style="filter: saturate(0);" src="https://mathproficiencytest.ca/img/account-icon.png">
                </div>
                <div class="table-holder">

                  <a class="button is-info" routerLink="/en/test-ctrl/reporting">View Reporting Explorer</a>
                  <div>
                    <div class="flex-header-container">
                      <!-- <h2>
                        Test Centre Summary
                      </h2>
                      <div class="flex-header-ctrl">
                        <button *ngIf="isInstSummaryLoaded" class="button is-info" (click)="loadInstSummary()">refresh</button>
                      </div> -->
                    </div>
                    <!-- <div *ngIf="!isInstSummaryLoading && !isInstSummaryLoaded">
                      <button class="button is-info" (click)="loadInstSummary()">Load Test Centre Summary</button>
                    </div> -->
                    <div *ngIf="isInstSummaryLoading">
                      Loading...
                    </div>
                    <div *ngIf="isInstSummaryLoaded && !isInstSummaryLoading && instSummaryList.length == 0" class="notification is-warning">
                      There is currently no test centre info
                    </div>
                    <div *ngIf="isInstSummaryLoaded && instSummaryList.length > 0" class="table-holder-container">
                      <table class="table is-bordered is-hoverable " style="min-width: 34em;">
                        <tr>
                          <th>Test Centre</th>
                          <th>City</th>
                          <th>Available Sessions</th>
                          <th>Available Capacity</th>
                          <th>Used Capacity</th>
                          <th>Pending Accomm. Req.</th>
                        </tr>
                        <tr *ngFor="let inst of instSummaryList">
                          <td>{{inst.name}}</td>
                          <td>{{inst.city}}</td>
                          <td>{{inst.sessions}}</td>
                          <td>{{inst.capacity}}</td>
                          <td>{{inst.booked}}</td>
                          <td>{{inst.pending_accomm}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

              <!-- <div style="margin-top:2em;">
                <a routerLink="/en/test-ctrl/seb-gen">seb config</a>
              </div> -->

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
  <footer [hasLinks]="true"></footer>
</div>
