import { IContentElementImage } from "../element-render-image/model";
import { IContentElementMath } from "../element-render-math/model";
import { IContentElementText } from "../element-render-text/model";
import { IContentElement, IEntryStateScored, IScoredResponse } from "../models";

export interface IContentElementSelectionTable extends IContentElement, IScoredResponse {
    topRow: IContentElementSelectionTableCell[];
    leftCol: IContentElementSelectionTableCell[];
    fontSize: number;
    checkBoxRows: IContentElementSelectionTableBool[][];
    isMustHaveOnePerRow: boolean;
    isMustHaveOnePerCol: boolean;
    allowMultipleAnswersPerRow?: boolean;
    allowMultipleAnswersPerColumn?: boolean;
    maxCheckedPerRow?: number;
    maxCheckedPerCol?: number;
    checkBoxSize: number;
    isQuestionWidthSet?: boolean;
    isAnswerWidthSet?: boolean;
    isAnswerHeightSet?: boolean;
    isMaxDenoScoring?:boolean;
    questionColumnWidth?: number;
    answerColumnWidth?: number;
    answerColumnHeight?: number;
    isFirstRowHeader?: boolean;
    isFirstColHeader?: boolean;
    configColWidth?: number;
    configRowWidth?: number;
    showAudio?: boolean;
    isRadio?: boolean;
    topLeftText?: IContentElementText;
    isLeftColumnHidden?: boolean;
    isTopRowHidden?: boolean;
    isElementInSelectableCell?: boolean;
    isCellPaddingSet?:boolean;
    leftCellPadding?: number;
    rightCellPadding?: number;
    topCellPadding?: number;
    bottomCellPadding?: number;
}

export interface IContentElementSelectionTableCell {
    content: any;
    alignText?: string;
    alignItems?: string;
    audio?: IContentElementImage;
}
  
export interface IContentElementSelectionTableBool {
    value: boolean;
  
    cellType?: string;
    mathCellElement?: IContentElementMath;
    textCellElement?: IContentElementText;
    imgCellElement?: IContentElementImage;
    selectedCellType?: string;
    mathCellSelectedElement?: IContentElementMath;
    textCellSelectedElement?: IContentElementText;
    imgCellSelectedElement?: IContentElementImage;
    isSelectionDisabled?: boolean;
    defaultColor?: string;
    selectedColor?: string;
}

export interface IEntryStateSelectionTable extends IEntryStateScored {
    checkMarks: { value: boolean }[][];
  }

  export const selectTableEditInfo= {
    editExcludeFields: ['topRow', 'leftCol', 'checkBoxRows', 'topLeftText']
}
