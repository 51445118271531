import { Injectable, ContentChild } from '@angular/core';
import { IActiveModal } from './modal/types.ts/model';

export interface IOverlayCancel {
  cancel: any;
}
@Injectable({
  providedIn: 'root'
})
export class PageModalService {

  constructor() { }

  currentOverlayCancel:IOverlayCancel;

  defineNewPageModal(){
    return new PageModalController();
  }

  updateActiveOverlay(cancelTarget:IOverlayCancel, isRevoking?:boolean){
    if (isRevoking && (cancelTarget === this.currentOverlayCancel)){
      this.currentOverlayCancel = null;
    }
    else{
      if (this.currentOverlayCancel && (cancelTarget !== this.currentOverlayCancel)){
        try {
          this.currentOverlayCancel.cancel();
        }
        catch(e){}
      }
      this.currentOverlayCancel = cancelTarget
    }
  }
}

export class PageModalController {

  currentModal:IActiveModal;
  isSaving:boolean;

  getCurrentModal(){
    return this.currentModal;
  }

  closeModal = (config?: any) => {
    this.currentModal = null;
  }

  confirmModal(closeModelAfter = true) {
    this.currentModal.finish(this.currentModal.config, closeModelAfter);
  }

  newModal = (modalConfig: IActiveModal) => {
    this.currentModal = modalConfig;
  }
}
