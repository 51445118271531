<ng-container *ngIf="isAuthenticated">  
  <test-runner
    *ngIf="testForm && !isShowingExternalResults()"
    [testFormType]="asmtFmrk.testFormType"
    [testFormId]="-1"
    [currentTestDesign]="testForm.currentTestDesign"
    [frameWorkTags]="asmtFmrk.tags"
    [questionSrcDb]="testForm.questionSrcDb"
    [questionStates]="testForm.questionStates"
    [testLang]="testForm.testLang"
    [testTakerName]="testTakerName"
    [sectionIndexInit]="0"
    [isTimeEnabled]="!asmtFmrk.isTimerDisabled"
    [documentItems]="asmtFmrk.referenceDocumentPages"
    [helpPageItem]="asmtFmrk.helpPageId"
    [rubricDownloadLink]="asmtFmrk.rubricDownloadLink"
    [asmtFmrk]="asmtFmrk"
    [questionIndexInit]="0"
    [regularTimeRemaining]="999"
    [isToolExploration] = isToolExploration
    [testSessionId]="-1"
    [saveQuestion]="saveQuestionResponse"
    [defaultZoomInit]="defaultZoom"
    [submitTest]="submitTest"
    [postSubmit]="showAnswers"
    [exitResults]="goHome"
    [isShowingResults]="isShowingEmbeddedResults()"
    (questionTitles)="questionTitleMap = $event"
    [forceQuestionsSkippable]="true"
    [btnReviewSubmit]="isToolExploration ? 'exit_tool_exploration' : 'btn_review_submit'"
    [noTestConfirmationRequired]="isToolExploration"
    [logAssetView]="logAssetView"
    [ignoreDevTools]="true"
    [logItemView]="logItemView"
  ></test-runner>

  <div *ngIf="!testForm" style="display:flex; justify-content: center; align-items: center; height:100vh;"> 
    <div *ngIf="!errorMessage">Loading...</div>
    <div *ngIf="errorMessage">{{errorMessage}}</div>
  </div>

  <external-results *ngIf="testForm && isShowingExternalResults()" [asmtFmrk]="asmtFmrk" [testState]="testState" [testForm]="testForm" [questionTitleMap]="questionTitleMap" [questionScores]="questionScores"></external-results>

</ng-container>
