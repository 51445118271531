import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthScopeSetting, AuthScopeSettingsService } from './auth-scope-settings.service';

@Injectable({
  providedIn: 'root'
})
export class EditingDisabledService {

  private isEditingDisabled = new BehaviorSubject<boolean>(false);
  private isCurrQLocked = new BehaviorSubject<boolean>(false);
  private isCurrQTrackingChanges = new BehaviorSubject<boolean>(false);
  
  constructor(private authScopeSettings: AuthScopeSettingsService) { 
  }

  setEditingDisabled(b:boolean) {
    this.isEditingDisabled.next(b);
  }

  setCurrQState(state: {isLocked:boolean, isTrackingChanges: boolean}){
    this.isCurrQLocked.next(state.isLocked);
    this.isCurrQTrackingChanges.next(state.isTrackingChanges);
  }

  getEditingDisabled() {
    return this.isEditingDisabled.getValue();
  }

  getCurrQLocked() {
    return this.isCurrQLocked.getValue();
  }

  getCurrQTrackingChanges() {
    return this.isCurrQTrackingChanges.getValue();
  }

  isReadOnly(ignoreCurrQLock: boolean = false, ignoreEditingDisabled: boolean = false) {
    return (this.authScopeSettings.getSetting(AuthScopeSetting.DISABLE_EDITING) || 
    (!ignoreEditingDisabled && this.getEditingDisabled()) || 
    (!ignoreCurrQLock && this.getCurrQLocked()));
  }
}