import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ScrollService } from '../../core/scroll.service';
import { AccountType } from '../../constants/account-types';
import { LoginGuardService } from '../../api/login-guard.service';
import { LangService } from '../../core/lang.service';
import { RoutesService } from '../../api/routes.service';
import { AuthService, getFrontendDomain } from '../../api/auth.service';
import { AUTH_REG_ERROR, regErrorMsgToTransSlug } from '../../api/constants/registration-validation';
import { PassValConstraint } from '../../api/constants/password-validation';
import { Subscription } from 'rxjs';
import { WhitelabelService } from 'src/app/domain/whitelabel.service'

interface ICreate {
  invitationCode: string, 
  firstName: string,
  lastName: string, 
  phoneNumber?: string,
  email: string,
  password: string,
  langCode? :string
  domain? :string
}

@Component({
  selector: 'view-create-account',
  templateUrl: './view-create-account.component.html',
  styleUrls: ['./view-create-account.component.scss']
})
export class ViewCreateAccountComponent implements OnInit {

  constructor(
    private loginGuard: LoginGuardService, // 
    public lang: LangService, // 
    private breadcrumbsService: BreadcrumbsService,
    private router:Router,
    private route:ActivatedRoute,
    private routes:RoutesService,
    private auth:AuthService,
    private scrollService: ScrollService,
    private whitelabelService: WhitelabelService,
  ) { }

  public breadcrumb = [];
  public isFormValidated:boolean;
  public isFormFailed:boolean;
  public formFailReason:string;
  public emailUsed:string
  public isPasswordPolicyShowing:boolean;
  private passedInEmail:string;
  private passedInFirstName:string;
  private passedInLastName:string;
  private passedInInvitationCode:string;
  private routeSub:Subscription;
  public AUTH_REG_ERROR = AUTH_REG_ERROR;
  public formGroup = new FormGroup({
    invitationCode: new FormControl(),
    givenName: new FormControl(),
    surname: new FormControl(),
    phoneNumber: new FormControl(),
    emailAddress: new FormControl(),
    emailAddressAlt: new FormControl(),
    password: new FormControl(),
    passwordConfirm: new FormControl(),
  })
  
  ngOnInit() {
    this.loginGuard.deactivate();
    this.auth.logout();
    this.scrollService.scrollToTop();
    this.breadcrumb = [
      // this.breadcrumbsService.TESTADMIN_LANDING(),
      this.breadcrumbsService._CURRENT( this.lang.tra('title_create_account'), this.router.url),
    ];
    this.routeSub = this.route.params.subscribe( routeParams => {
      const parseEncoded = (param:string) => {
        const encoded = routeParams[param];
        if (encoded){
          return decodeURIComponent(encoded);
        }
        return '';
      }
      this.passedInEmail = parseEncoded('emailEncoded');
      this.passedInFirstName = parseEncoded('firstName');
      this.passedInLastName = parseEncoded('lastName');
      this.passedInInvitationCode = routeParams['invitationCode'];
      // emailEncoded/:invitationCode
      this.initRouteView();
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  initRouteView(){
    this.formGroup.controls['invitationCode'].setValue(this.passedInInvitationCode);
    this.formGroup.controls['emailAddress'].setValue(this.passedInEmail);
    this.formGroup.controls['givenName'].setValue(this.passedInFirstName);
    this.formGroup.controls['surname'].setValue(this.passedInLastName);
  }

  togglePasswordPolicy(){
    this.isPasswordPolicyShowing = !this.isPasswordPolicyShowing;
  }

  isEqaoStyle = () => {
    return this.whitelabelService.getSiteFlag('EQAO_LOGIN')
  }

  isSubmitting:boolean;
  submitForm(){
    this.isSubmitting = true;
    this.isFormFailed = false;
    this.createAccount()
      .then(res => {
        this.isFormValidated = true;
        this.scrollService.scrollToTop();
        this.isSubmitting = false;
      })
      .catch(err => {
        // console.warn('createAccount fail', err)
        this.isFormFailed = true;
        this.isSubmitting = false;
        this.formFailReason = regErrorMsgToTransSlug(err.message);
        this.refreshFailReason();
      })
  }

  isRefreshingFailReason:boolean;
  private refreshFailReason(){
    this.isRefreshingFailReason = true;
    setTimeout(() => this.isRefreshingFailReason = false, 500)
  }


  private createAccount(){

    const invitationCode = this.formGroup.controls.invitationCode.value
    const firstName = this.formGroup.controls.givenName.value
    const lastName = this.formGroup.controls.surname.value
    const phoneNumber = this.formGroup.controls.phoneNumber.value
    const email = this.formGroup.controls.emailAddress.value
    const password = this.formGroup.controls.password.value
    const passwordConfirm = this.formGroup.controls.passwordConfirm.value


    if (!invitationCode){ return Promise.reject({ message: 'MISSING_INVITATION' }) }
    if (!email){ return Promise.reject({ message: 'MISSING_EMAIL' }) }
    if (!password){ return Promise.reject({ message: 'MISSING_PASSWORD' }) }
    if (!firstName){ return Promise.reject({ message: 'MISSING_FIRSTNAME' }) }
    if (!lastName){ return Promise.reject({ message: 'MISSING_LASTNAME' }) }
    if (!password){ return Promise.reject({ message: 'MISSING_PASSWORD' }) }
    if (password !== passwordConfirm){ return Promise.reject({ message: 'PASS_MATCH' }) }
    
    if (!this.validateEmail(email)){ return Promise.reject({ message: 'EMAIL_MALFORMED' }) }

    this.emailUsed = email;

    const req:ICreate = {
      invitationCode,
      firstName,
      lastName,
      phoneNumber,
      email,
      password,
      langCode : this.lang.c(),
      domain: getFrontendDomain()
    }

    //alert('This function has been disabled for the demo');
    //return;

    return this.auth
      .apiCreate(
        this.routes.AUTH_TEST_ADMIN,
        req,
        {}
      )
  }

  private validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}
