export enum AccountType {
    TEST_ADMIN = 'test-admin',
    TEST_ADMIN_INVIG = 'mpt-test-admin-invig',
    DIST_ADMIN = 'dist-admin',
    TEST_TAKER = 'test-taker',
    TEST_CTRL = 'test-ctrl',
    TEST_CTRLD = 'test-ctrl-data',
    CERT_BODY = 'test-cert',
    SUPPORT = 'support',
    TEST_AUTH = 'test-auth',

    SCHOOL_ADMIN = 'school-admin',
    EDUCATOR = 'educator',
    STUDENT = 'student',
    //MINISTRY_ADMIN = 'ministry-admin',

    MINISTRY_ADMIN = 'ministry-admin',

    MRKG_CTRL = 'mrkg-ctrl',
    MRKG_SUPR = 'mrkg-supr',
    MRKG_MRKR = 'mrkg-mrkr',
    MRKG_CAND = 'mrkg-cand',
    MRKG_UPLD = 'mrkg-upld',

    SCOR_SCOR = 'scor-scor',
    SCOR_SUPR = 'scor-supr',
    SCOR_RAFI = 'scor-rafi',
    SCOR_LEAD = 'scor-lead',

    MRKG_COORD = 'mrkg-coord',
    MRKG_LEAD = 'mrkg-lead',

    BC_GRAD_MINISTRY_ADMIN = 'test-ctrl-adm-bcgrad-ministry',
    BC_GRAD_DIST_ADMIN = 'test-ctrl-adm-bcgrad-district',
    BC_GRAD_SCHOOL_ADMIN = 'test-ctrl-adm-bcgrad-school',
    BC_FSA_MINISTRY_ADMIN = 'test-ctrl-adm-bcfsa',
    BC_FSA_DIST_ADMIN = 'test-ctrl-adm-bcfsa-district',
    BC_FSA_SCHOOL_ADMIN = 'test-ctrl-adm-bcfsa-school',
    BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY = 'test-ctrl-adm-bcfsa-school-score-entry',
    BC_FSA_DIST_ADMIN_SCORE_ENTRY = 'test-ctrl-adm-bcfsa-district-score-entry',
}
