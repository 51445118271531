import { IContentElement, IEntryStateScored } from "../models";
import { IQPubSubPayload } from "../question-runner/pubsub/question-pubsub";

export interface IContentElementMic extends IContentElement {
    setTimer?: boolean;
    isPractice?: boolean;
    time?: number;
    minDuration?: number;
    maxDuration?: number;
    onCountdownEnd?: IQPubSubPayload[];
  }

export interface IEntryStateMic extends IEntryStateScored {
    fileType?: string;
    url?: string;
    time?: number;
}

export const micEditInfo = {
  editExcludeFields: []
}