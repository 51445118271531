import * as moment from 'moment-timezone';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountType } from '../../constants/account-types';
import { LoginGuardService } from '../../api/login-guard.service';
import { MyCtrlOrgService } from '../my-ctrl-org.service';
import { AuthService, DB_TIMEZONE } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { ScrollService } from '../../core/scroll.service';
import { LangService } from '../../core/lang.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreadcrumbsService, IBreadcrumbRoute } from '../../core/breadcrumbs.service';
import { ITCDashboardSummaryRes } from '../types/api-response';
import { ITestWindowSummary, IAccountsSummary } from './types/db';
import { FormControl } from '@angular/forms';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { IMenuTabConfig } from '../../ui-partial/menu-bar/menu-bar.component';
import { userGroupTypes, userGroupSummaryColumns } from './types/tc-summary';
import { CODEBOOK_G9, tcDataDlApiPrefix } from './types/codebook-g9';
import { transformThousandsSeparator } from '../../core/styleprofile.service'
interface ITestWindow {
  id: number,
  captionStr: string,
  dateStart: string
  dateEnd: string,
  numSessionsCreated: number,
  numSessionsAdministered: number,
  numResultsPendingRelease: number,
}

interface ILangReqRow {
  testSessionId: number,
  date: string,
  time: string,
  location:string,
  locationInst: string,
  locationDetails: string,
  lang: string,
  tally: number,
  isOrganized:boolean,
  notes: string,
  __notesInput: FormControl,
  __isShowLocDetail?: boolean,
}

export interface ILangReqRecord {
  id: number, // test session id
  test_window_id: number,
  lang_req: string,
  tally: number,
  institution_name: string,
  room: string,
  campus_building: string,
  address: string,
  city: string,
  province: string,
  postal_code: string,
  phone_number: string,
  date_time_start: string,
}
 enum UserGroupType {
  SCHOOL_BOARDS = 'districts',
  SCHOOLS = 'schools',
  TEACHERS = 'teachers',
  SCHOOL_CLASSES = 'classes',
  STUDENTS = 'students',
}

export enum TCView {
  EQAO_G9 = 'EQAO_G9',
  EQAO_G10 = 'EQAO_G10',
  EQAO_MPT = 'EQAO_MPT',
}


@Component({
  selector: 'view-tc-dashboard',
  templateUrl: './view-tc-dashboard.component.html',
  styleUrls: ['./view-tc-dashboard.component.scss']
})
export class ViewTcDashboardComponent implements OnInit, OnDestroy {

  // services
  constructor(
    private loginGuard: LoginGuardService, //
    private router:Router,
    private route:ActivatedRoute,
    private breadcrumbsService:BreadcrumbsService,
    private scrollService:ScrollService,
    private lang:LangService,
    private auth:AuthService,
    private routes:RoutesService,
    private myCtrlOrg:MyCtrlOrgService,
    private whitelabel:WhitelabelService,
  ) { }

  // rendered vars

  public districts = 'districts';
  public isDisableHidden:boolean = true;
  public breadcrumb:IBreadcrumbRoute[];
  public isInited:boolean;
  public isLoaded:boolean;
  public isLoadFailed:boolean;
  public testWindowSummaries:ITestWindow[];
  public testWindowOldSessions = {};
  public accountSummaries:IAccountsSummary;
  public showLast30days = new FormControl(false);
  public showTestSessionInProgressData = new FormControl(false);
  public TCView = TCView;
  public selectedView:TCView =TCView.EQAO_G9; // to change if ordering by route
  public IS_DOWNLOADS_DISABLED = true;
  public IS_COUNTS_DISABLED = true;
  public views:IMenuTabConfig<TCView>[] = [
    {
      id:TCView.EQAO_G9,
      caption: this.lang.tra('Grade 9 Mathematics'), //'Technical Readiness',
    },
    {
      id:TCView.EQAO_G10,
      caption: this.lang.tra('OSSLT/TPCL'), //'Technical Readiness',
    },
    {
      id:TCView.EQAO_MPT,
      caption: this.lang.tra('Math Proficiency Test'), //'Technical Readiness',
    }
  ];
  public userGroupTypes = userGroupTypes;
  public UserGroupType = UserGroupType;
  public userGroupSummaryColumns = userGroupSummaryColumns;
  public codebookTable = CODEBOOK_G9;

  // logical vars
  private routeSub:Subscription;
  private myCtrlOrgSub:Subscription;


  // init / destroy
  ngOnInit() {
    this.scrollService.scrollToTop();
    this.loginGuard.activate([AccountType.TEST_CTRL]);
    this.routeSub = this.route.params.subscribe(routeParams => {
      // this.setupId = routeParams['setupId'];
      this.breadcrumb = [
        this.breadcrumbsService.TESTCTRL_DASHBOARD(),
        // this.breadcrumbsService._CURRENT(this.lang.tra(currentPageNameTrans), this.router.url),
      ]
      this.initRouteView();
    });
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }

    if (this.myCtrlOrgSub) {
      this.myCtrlOrgSub.unsubscribe();
    }
  }

  initRouteView(){
    this.myCtrlOrgSub = this.myCtrlOrg.sub().subscribe(orgInfo => {
      if (!this.isInited && orgInfo){
        this.isInited = true;
        this.selectView(this.selectedView)
      }
    })
  }



  // getBaseRoute(){
  //   return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}`;
  // }
  // getViewRoute(viewSlug:TCView){
  //   return this.getBaseRoute()+'/'+viewSlug
  // }
  loadedDataTracker: TCView;
  g9Summary:any = {};

  selectView(id: TCView){
    this.selectedView = id;
    if (this.loadedDataTracker === id){ return }
    if (id === TCView.EQAO_G9){
      this.loadedDataTracker = id;
      this.loadG9SummaryData();
    }
    if (id === TCView.EQAO_G10) {
      this.loadedDataTracker = id;
      this.auth
        .apiGet( this.routes.TEST_CTRL_DATA_FILE, this.g10SelectedWindow.ttriFileId, {} )
        .then( (file: any) => {
          this.g10TtriUrl = file.secureUrl;
        })
        .catch(e => {
          alert('Cannot make this file available for you.');
        });
      this.loadG10SummaryData();
    }
    if (id === TCView.EQAO_MPT){
      if (this.isInited){
        this.loadedDataTracker = id;
        this.loadMPTSummaryData();
      }
    }
    // this.router.navigate([this.getViewRoute(id)]);
    // this.updateBreadcrumb();
  }

  // API utility
  currentG9SummaryColIndex = 0;
  g9TestWindows = [
    // {
    //   id: 6,
    //   projectId: 151,
    //   dateRange: 'Oct. 22 to Nov. 27',
    //   status: 'Completed',
    // }
    // ,{
    //   id: 7,
    //   projectId: 152,
    //   dateRange: 'Dec. 3 to Dec. 18',
    //   status: 'Completed',
    // }
    // ,{
    //   id: 8,
    //   projectId: 153, // ran it under the same payload
    //   dateRange: 'Jan. 11 to Jan. 25',
    //   status: 'Completed',
    // },{
    //   id: 9,
    //   projectId: 154,
    //   dateRange: 'Feb. 9 to Jun. 25',
    //   status: 'Active',
    // },{
    //   id: 11,
    //   projectId: 155,
    //   dateRange: 'Feb. 9 to Jun. 25',
    //   status: 'Active',
    // },{
    //   id: 12,
    //   projectId: 156,
    //   dateRange: 'Feb. 9 to Jun. 25',
    //   status: 'Active',
    // },
    // {
    //   id: 13,
    //   projectId: 157,
    //   dateRange: 'Feb. 9 to Jun. 25',
    //   status: 'Active',
    // }
    {
      id: 25,
      projectId: 158,
      dateRange: 'Fall 2021',
      status: 'Active',
    }
  ]
  g9SelectedWindow = this.g9TestWindows[0];
  g9SummaryTable:{[col:string]: {[row:string]: number}} = {};

  currentG10SummaryColIndex = 0;
  g10TestWindows = [
    {
      id: 10,
      projectId: 138,
      dateRange: 'March 23 to June 4',
      status: 'Pending',
      ttriFileId: 8
    }
  ];
  g10SelectedWindow = this.g10TestWindows[0];
  g10SummaryTable:{[col:string]: {[row:string]: number}} = {};
  g10TtriUrl = null;

  selectTestWindowFocus(window){
    this.g9SelectedWindow = window;
    this.loadG9SummaryData()
  }
  selectG10TestWindowFocus(window) {
    this.g10SelectedWindow = window;
    this.auth
      .apiGet( this.routes.TEST_CTRL_DATA_FILE, this.g10SelectedWindow.ttriFileId, {} )
      .then( (file: any) => {
        this.g10TtriUrl = file.secureUrl;
      })
      .catch(e => {
        alert('Cannot make this file available for you.');
      });
    this.loadG10SummaryData();
  }
  checkActiveWindow(window){
    return (this.g9SelectedWindow === window);
  }
  checkActiveG10Window(window) {
    return (this.g10SelectedWindow === window);
  }
  downloadDataFrame(dataFrame:{apiPath:string}){
    return this.auth.dataFilePath(tcDataDlApiPrefix+dataFrame.apiPath, {projectId: this.getCurrentProjectId()});
  }
  downloadG10DataFrame(dataFrame:{apiPath:string}){
    const projectId = this.getCurrentG10ProjectId();
    if (projectId == 138 && dataFrame.apiPath == 'registrations/students') {
      return this.g10TtriUrl;
    }
    return this.auth.dataFilePath(tcDataDlApiPrefix+dataFrame.apiPath, { projectId });
  }
  getRawLink(userGroupType:any){
    return this.auth.dataFilePath(tcDataDlApiPrefix+userGroupType.apiPath, {projectId: this.getCurrentProjectId()});
  }
  getColLink(userGroupType:string){

    let path = `school-${userGroupType}`;
    if(userGroupType === UserGroupType.SCHOOL_BOARDS) {
      path = 'school-boards';
    } else if (userGroupType === UserGroupType.SCHOOLS) {
      path = 'school';
    }
   return `/en/test-ctrl/${path}`;
  }
  getColValue(userGroupType:string, col:string){
    const colData = this.g9SummaryTable[col];
    if (colData){
      if (colData[userGroupType] !== undefined){
        return transformThousandsSeparator(''+colData[userGroupType], 'en');
      }
    }
    return '--'
  }
  getG10ColValue(userGroupType:string, col:string){
    const colData = this.g10SummaryTable[col];
    if (colData){
      if (colData[userGroupType] !== undefined){
        return transformThousandsSeparator(''+colData[userGroupType], 'en');
      }
    }
    return '--'
  }
  loadG9SummaryData(){
    this.currentG9SummaryColIndex = 0;
    this.g9SummaryTable = {};
    this.loadRemainingG9Summary();
  }
  loadG10SummaryData(){
    this.currentG10SummaryColIndex = 0;
    this.g10SummaryTable = {};
    this.loadRemainingG10Summary();
  }
  getCurrentProjectId(){
    return this.g9SelectedWindow.projectId;
  }
  getCurrentG10ProjectId(){
    return this.g10SelectedWindow.projectId;
  }
  loadRemainingG9Summary(){
    const col = this.userGroupSummaryColumns[this.currentG9SummaryColIndex];
    this.myCtrlOrg
      .getG9Summaries(col.id, this.getCurrentProjectId())
      .then(data => {
        this.isLoaded = true; // after the first one
        this.g9SummaryTable[col.id] = data;
        this.currentG9SummaryColIndex ++;
        if (this.currentG9SummaryColIndex < this.userGroupSummaryColumns.length){
          this.loadRemainingG9Summary();
        }
        else{
          console.log('g9SummaryTable', this.g9SummaryTable)
        }
      })
  }
  loadRemainingG10Summary(){
    const col = this.userGroupSummaryColumns[this.currentG10SummaryColIndex];
    this.myCtrlOrg
      .getG10Summaries(col.id, this.getCurrentG10ProjectId())
      .then(data => {
        this.isLoaded = true; // after the first one
        this.g10SummaryTable[col.id] = data;
        this.currentG10SummaryColIndex ++;
        if (this.currentG10SummaryColIndex < this.userGroupSummaryColumns.length){
          this.loadRemainingG10Summary();
        }
        else{
          console.log('g10SummaryTable', this.g10SummaryTable)
        }
      })
  }
  loadMPTSummaryData(){
    return this.auth
      .apiGet( this.routes.TEST_CTRL_SUMMARY, 0, {} )
      .then( (res:ITCDashboardSummaryRes) => {
        this.isLoaded = true;
        this.accountSummaries = res.accounts;
        this.testWindowSummaries = res.test_windows.map(this.parseTestWindowSummary);

        const testWindowIds = res.test_windows.map(window => window.test_window_id);
        this.loadSUnclosedSessions(testWindowIds);
      })
      .catch(e => {
        this.isLoadFailed = true;
      })
  }

  loadSUnclosedSessions(testWindowIds) {
    this.auth.apiFind('public/test-ctrl/sessions-date', {
      query: {
        test_window_ids: testWindowIds
      }
    }).then(res => {
      res.data.forEach(sess => {
        const windowId = sess.id;
        const isClosed = sess.is_closed;
        const startDate = sess.date_time_start;

        const start = moment(startDate);
        const now = moment().subtract(6, 'hour');

        if (start.isBefore(now)) {

          if (windowId in this.testWindowOldSessions) {
            if (!isClosed) {
              this.testWindowOldSessions[windowId] += 1;
            }
          } else {
            if (!isClosed) {
              this.testWindowOldSessions[windowId] = 1;
            }
          }

        }
      });

    });
  }

  createTestWindow(){
    if (confirm('Proceed in creating a new test window?')){
      return this.auth
        .apiCreate( this.routes.TEST_CTRL_TEST_WINDOW_SUMMARY, {})
        .then( (res:{id:number} ) => {
          this.router.navigate([ this.getTestWindowRoute(res.id) ])
        })
        .catch(e => {
          alert('Cannot create new test window.')
        })
    }
    }

  isLangReqLoaded:boolean;
  isLangReqLoading:boolean;
  isLangReqLoadFailed:boolean;
  langReqRows:ILangReqRow[] = [];
  loadLangReqData(){
    this.langReqRows = []
    this.isLangReqLoading = true;
    return this.auth
      .apiFind( this.routes.TEST_CTRL_LANG_REQ, {} )
      .then( (res:any) => { // :Paginated
        this.isLangReqLoaded = true;
        this.langReqRows = res.data.map(this.parseLangReqRecord);
        this.isLangReqLoading = false;
        // console.log('loadLangReqData', this.langReqRows)
      })
      .catch(e => {
        this.isLangReqLoadFailed = true;
      })
  }


  isInstSummaryLoaded:boolean;
  isInstSummaryLoading:boolean;
  isInstSummaryLoadFailed:boolean;
  instSummaryList:any[] = [];
  loadInstSummary(){
    this.instSummaryList = []
    this.isInstSummaryLoading = true;
    return this.auth
      .apiFind( this.routes.TEST_CTRL_ACCOUNTS_INSTITUTIONS, {} )
      .then( (res:any) => { // :Paginated
        this.isInstSummaryLoaded = true;
        this.instSummaryList = res.data.sort((a,b)=>{
          if (a.name > b. name){ return 1; }
          if (a.name < b. name){ return -1; }
          return 0;
        });
        this.isInstSummaryLoading = false;
      })
      .catch(e => {
        this.isInstSummaryLoadFailed = true;
      })
  }

  parseLangReqRecord = (langReqRecord:ILangReqRecord) : ILangReqRow => {
    const DATE_FMT = 'MMM D, YYYY'
    const TIME_FMT = this.lang.tra('datefmt_time')
    const m = moment(langReqRecord.date_time_start);
    return {
      testSessionId: langReqRecord.id,
      date: m.format(DATE_FMT),
      time: m.format(TIME_FMT),
      location:langReqRecord.city,
      locationInst: langReqRecord.institution_name,
      locationDetails: [
        `${langReqRecord.room}, ${langReqRecord.campus_building}`,
        `${langReqRecord.address}`,
        `${langReqRecord.city} ${langReqRecord.province} ${langReqRecord.postal_code}`,
        `${langReqRecord.phone_number}`,
      ].join('\n'),
      lang: this.processLangReqCode(langReqRecord.lang_req),
      tally: langReqRecord.tally,
      isOrganized: false,
      notes: '',
      __notesInput: new FormControl(),
    }
  }

  private processLangReqCode(langCode:string){
    if (langCode === 'fr'){
      return 'French';
    }
    return 'Other';
  }

  parseTestWindowSummary = (tws:ITestWindowSummary) : ITestWindow => {
    const dateFmt = this.lang.tra('datefmt_dow_time_day_month_year');
    const dateStart = moment.tz(tws.test_window_date_start, moment.tz.guess()).format(dateFmt);
    const dateEnd = moment.tz(tws.test_window_date_end, moment.tz.guess()).format(dateFmt);
    return {
      id: tws.test_window_id,
      captionStr: tws.test_window_name ? tws.test_window_name[this.lang.c()] : '',
      dateStart,
      dateEnd,
      numSessionsCreated: tws.num_sessions_created,
      numSessionsAdministered: tws.num_sessions_administered,
      numResultsPendingRelease: tws.num_results_pending_release,
    }
  }

  // DOM utility
  disabled(){
    if (!this.isDisableHidden){
      this.loginGuard.confirmationReqActivate({
        caption: this.lang.tra('txt_feature_disabled'),
        confirm: ()=>{}
      })
    }
  }

  getTestWindowRoute = (id:string | number) => `/${this.lang.c()}/${AccountType.TEST_CTRL}/test-window/window/${id}` ;

  refreshData(){

  }

  getAccountsRoute(){
    return `/${this.lang.c()}/${AccountType.TEST_CTRL}/accounts/${AccountType.TEST_ADMIN}`;
  }

  isTestCtrlReportingExplorerEnabled(){
    return this.whitelabel.getSiteFlag('TEST_CTRL_REPORT_EXPLR');
  }

  // testWindowSummaries

  renderTwTitle(tw:ITestWindow){
    return tw.captionStr;
  }
  renderTwDateStart(tw:ITestWindow){
    return tw.dateStart;
  }
  renderTwDateEnd(tw:ITestWindow){
    return tw.dateEnd;
  }
  renderTwNumSessionsCr(tw:ITestWindow){
    return tw.numSessionsCreated;
  }
  renderOldUnclosed(tw:ITestWindow) {
    return this.testWindowOldSessions[tw.id] || 0;
  }
  renderTwNumSessionsAdm(tw:ITestWindow){
    return tw.numSessionsAdministered;
  }
  renderTwNumResPend(tw:ITestWindow){
    return tw.numResultsPendingRelease;
  }
  // end of: testWindowSummaries
  // Account Summaries
  renderAccountTally(set:string, subset: 'r' | 'l30' | 'p'){
    let val;
    if (this.accountSummaries){
      const tallySummary = this.accountSummaries[set];
      if (tallySummary){
        val = tallySummary[subset];
      }
    }
    return val || 0;
  }


  // end of: Account Summaries
  renderDateFromStr(dateString:string){
    return moment.tz(dateString, moment.tz.guess()).format();
  }
  isOnFirstPage(){
    return true;
  }
  isOnLastPage(){
    return true;
  }
  getCurrentPageNum(){
    return 1
  }
  getMaxPages(){
    return 1
  }


}
