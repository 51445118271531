import { IContentElementTextLink } from "../element-render-text-link/model";
import { FontFamily, IContentElement } from "../models";

export interface IContentElementText extends IContentElement {
    caption: string;
    paragraphStyle: TextParagraphStyle;
    simpleList?: string[];
    advancedList: IContentElement[];
    paragraphList?: IContentElementTextParagraph[];
    paragraphNumberingPos?: NumberingPosition;
    paragraphNumberingMargin?: number;
    paragraphNumberingScale?: number;
    paragraphNumberingStart?: number;
    paragraphSpaceAfter?: number; // em
    link?: IContentElementTextLink;
    isAdvancedOptionsDisabled?: boolean;
    font?: FontFamily;
    alignment?: string;
    rotation?: number;
    paragraphWidth?: number; // used for paragraphs
    fontSize?: number;
    lineHeight?: number;
    isInvisible?: boolean;
    advancedInlineWidth?: number;
    colour?: string;
    isShowDynamicText?: boolean;
    fromEntryId?: number;
}

export enum NumberingPosition {
    LEFT = "LEFT",
    RIGHT = "RIGHT",
    NONE = "NONE"
}

export enum TextParagraphStyle {
    HEADLINE = "headline",
    HEADLINE_SMALL = "headline_small",
    REGULAR = "regular",
    SMALL = "small",
    BULLET = "bullet",
    NUMBERED = "numbered",
    PARAGRAPHS = "paragraphs",
    ADVANCED_INLINE = "advanced-inline",
    LINK = "link",
    ANNOTATION = "annotation"
}

export interface IContentElementTextParagraph extends IContentElement {
    caption?: string;
    paddingRight?: number;
    paddingLeft?: number;
    paragraphSpaceAfter?: number;
    numberingOverride?: string;
}

export const textEditInfo = {
    editExcludeFields: ['advancedList', 'link'],
    editTextFields: ['caption']
}

export const textParagraphEditInfo = {
    editExcludeFields: [],
    editTextFields: ['caption']
}