export enum ScoringLeaderView {
  SUPERVISORS = 'supervisors',
  
  ACCOUNTS = 'accounts',
  ASSESSMENT_FORMS = 'assessment-forms',
  TRAINING = 'training',
  SCORERSTATS = 'scorerstats',
  ITEMS = 'items',
  RESPONSES = 'responses',

  // MONIT_VALIDITY = 'monit-validity',
  // MONIT_TRAINING = 'monit-training',
}


const LEADER_ACCOUNTS = {
  id:ScoringLeaderView.ACCOUNTS, 
  caption: ('Accounts'), // lbl_scorers
  imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/project/1610324458874/project.png',
  description: ('Invite scorers, supervisors, and review roles.'), // txt_scorers_btn
  sideStats: [
    {caption:'{{SCOR_ACCT_INVIT}} invited'},
    {caption:'{{SCOR_ACCT}} confirmed'},
    {caption:'{{SCOR_TRAIN}} are trained'},
    {caption:'{{SCOR_SCOR}} are scoring'},
    {caption:'{{SCOR_BATCHES}} batches claimed'},
  ]
};
export const LEADER_ASSESSMENT_FORMS = {
  id:ScoringLeaderView.ASSESSMENT_FORMS, 
  caption: ('Assessment Forms'), // lbl_items_and_responses
  imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/algorithms/1610324520257/algorithms.png',
  description: (''), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
  sideStats: [
    {caption:'{{ITEMS}} items'},
    {caption:'{{SCHOOLS}} schools'},
    {caption:'{{STUDENTS}} students'},
  ]
}
export const LEADER_SCORING_SUMMARIES = {
  id:ScoringLeaderView.ITEMS, 
  caption: ('Item Summaries'), // lbl_items_and_responses
  imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/algorithms/1610324520257/algorithms.png',
  description: ('View & monitor data by item.'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
  sideStats: [
    {caption:'{{ITEMS}} items'},
    {caption:'{{SCHOOLS}} schools'},
    {caption:'{{STUDENTS}} students'},
  ]
}
export const LEADER_DETAILED_SCORING_DATA = {
  id:ScoringLeaderView.RESPONSES, 
  caption: ('Responses'), // lbl_items_and_responses
  imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/algorithms/1610324520257/algorithms.png',
  description: ('View & monitor data by response.'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
  sideStats: [
    {caption:'{{ITEMS}} items'},
    {caption:'{{SCHOOLS}} schools'},
    {caption:'{{STUDENTS}} students'},
  ]
}
export const LEADER_ITEMS = {
  id:ScoringLeaderView.ITEMS, 
  caption: ('Items'), // lbl_items_and_responses
  imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/algorithms/1610324520257/algorithms.png',
  description: ('Review item progress and select exemplars'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
  sideStats: [
    {caption:'{{ITEMS}} items'},
    {caption:'{{SCHOOLS}} schools'},
    {caption:'{{STUDENTS}} students'},
  ]
}
const LEADER_ITEM_SCORERS = {
  id:ScoringLeaderView.SCORERSTATS, 
  caption: ('Item Scorers'), // lbl_scorers
  imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/project/1610324458874/project.png',
  description: ('View scorer progress, send messages, and apply blocks.'), //txt_scorers_btn
  sideStats: [
    {caption:'{{SCOR_BATCHES}} batches claimed'},
  ]
};
export const LEADER_RESPONSES = {
  id:ScoringLeaderView.RESPONSES, 
  caption: ('Response Monitoring'), // lbl_items_and_responses
  imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/algorithms/1610324520257/algorithms.png',
  description: ('View individually scored response, apply filters, sort, export, or apply single or batch actions.'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
  sideStats: [
    {caption:'{{RESPONSES}} responses'},
    {caption:'{{RESPONSES_SCORED}} scored'},
    {caption:'{{RESPONSE_FLAGS}} new flags'},
  ]
}

const LEADER_TRAINING = {
  id:ScoringLeaderView.TRAINING, 
  caption: 'Validity & Training Materials Selection',
  imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/authorization/1610324576927/authorization.png',
  description: 'View training materials and range finding.' // '67 students are currently enrolled. Click here to validated their information and re-assign them to different teachers.',
}

export const SCORING_LEADER_VIEWS = [
    // {
    //   id:ScoringLeaderView.SUPERVISORS, 
    //   caption: ('Supervisors'), 
    //   imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/online/1610324506178/online.png',
    //   description: ('Upload and manage supervisor accounts.'), //'',
    //   sideStats: [
    //     {caption:'{{SUPR_ACCT_INVIT}} invited'},
    //     {caption:'{{SUPR_ACCT}} confirmed'},
    //   ]
    // },

    // LEADER_SCORERS,
    // LEADER_ITEMS,
    // LEADER_TRAINING,
    // LEADER_SCORERSTATS,
    // LEADER_RESPONSES,

    LEADER_ACCOUNTS,
    LEADER_ASSESSMENT_FORMS,
    LEADER_TRAINING,
    LEADER_ITEM_SCORERS,
    LEADER_SCORING_SUMMARIES,
    LEADER_DETAILED_SCORING_DATA
   
    // {
    //   id:ScoringLeaderView.MONIT_TRAINING, 
    //   caption: ('g9_students'), //'Students',
    //   imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/students/1602128010278/students.png',
    //   description: ('txt_students') // '67 students are currently enrolled. Click here to validated their information and re-assign them to different teachers.',
    // },
]