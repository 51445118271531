<div class="progress-bar-container" [class.less-padding]="useLessPadding">
    <!-- <div class="question-loc-sm">
        <tra slug="title_stage"></tra> {{testState.currentSectionIndex+1}},
        {{getQuestionTitle(testState.currentQuestionIndex)}}
    </div> -->
    <div  style="position:relative" #topBar [class.is-hidden]="isHidden" [ngSwitch]="useProgressBySession">
        <ng-container *ngSwitchCase="false || undefined">
            <progress  class="progress is-info" style="margin-bottom:0px;" [value]="currProgressLocAsNum" max="100">{{currProgressLoc}}</progress>
            <div *ngFor="let section of testRunnerSections; let isLast = last" class="section-progress-marker" [ngStyle]="{left: section.__meta.markLoc}" [class.is-hidden]="isLast"> </div>
        </ng-container>
        <ng-container  *ngSwitchCase="true">
            <progress class="progress is-info" style="margin-bottom:0px;" [value]="currProgressBySession" max="100"></progress>
        </ng-container>
    </div>
</div>