import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tr-progress-bar',
  templateUrl: './tr-progress-bar.component.html',
  styleUrls: ['./tr-progress-bar.component.scss']
})
export class TrProgressBarComponent implements OnInit {

  constructor() { }

  @Input() currProgressLoc;
  @Input() currProgressLocAsNum;
  @Input() currProgressBySession;
  @Input() isHidden;
  @Input() useProgressBySession;
  @Input() testRunnerSections;
  @Input() useLessPadding; 

  ngOnInit(): void {
  }
}
