import { ITestDesignPayload } from "../../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component";
// app services
import { AssetLibraryService, IAssetLibraryConfig} from '../../services/asset-library.service';
import { AssignedUsersService } from '../../assigned-users.service';
import { AuthScopeSettingsService, AuthScopeSetting } from "../../auth-scope-settings.service";
import { AuthService } from '../../../api/auth.service';
import { DataGuardService } from '../../../core/data-guard.service';
import { EditingDisabledService } from '../../editing-disabled.service';
import { ItemComponentEditService } from '../../item-component-edit.service';
import { ItemMakerService } from '../../item-maker.service';
import { LangService } from '../../../core/lang.service';
import { LoginGuardService } from '../../../api/login-guard.service';
import { PrintAltTextService } from '../../print-alt-text.service';
import { PrintModeService } from '../../print-mode.service';
import { RoutesService } from '../../../api/routes.service';
import { ScriptGenService } from '../../script-gen.service';
import { EStyleProfile, StyleprofileService } from '../../../core/styleprofile.service';
import { WhitelabelService } from '../../../domain/whitelabel.service';

export interface IAssessmentFrameworkDef {
  __id?: string,
  projectId?: string,
  caption: string,
  subcaption?: string,
  uidCreator?: string,
  uidLastTouch?: string, 
  timeCreated?: any,
  timeLastTouch?: any,
}
export interface IQuadrantConstraint { // only equality
  param: string,
  val: string,
}
export enum TestletConstraintFunction {
  MATCH = 'MATCH',
  VARIETY = 'VARIETY',
  AVG = 'AVG',
  STDEV = 'STDEV',
}
export interface ITestletConstraintCommon {
  param: string, 
  isMin: boolean,
  isMax: boolean,
  isEqual: boolean,
  // isProportion: boolean,
}
export interface ITestletConstraint_MATCH extends ITestletConstraintCommon {
  val: string | number,
  count: number,
}
export interface ITestletConstrain_VARIETY extends ITestletConstraintCommon {
  count: number,
}
export interface ITestletConstrain_AVG extends ITestletConstraintCommon {
  val: number,
}
export interface ITestletConstrain_STEDEV extends ITestletConstraintCommon {
  val: number,
}
export interface ITestletConstraint {
  weight: number,
  func: TestletConstraintFunction,
  config: ITestletConstraint_MATCH | 
  ITestletConstrain_VARIETY | 
  ITestletConstrain_AVG | 
  ITestletConstrain_STEDEV,
}
export interface IQuadrantTestletConfig {
  numItems: number,
  discardThreshold: number,
  triesBeforeFail: number,
  constraints: ITestletConstraint[],
}
export interface IQuadrant {
  id: number, // dont show to user
  section: number,
  constraints: IQuadrantConstraint[],
  description: string,
  config: IQuadrantTestletConfig,
}



export interface IQuadrantTestlet {
  id: number, // dont show to user
  section: number,
  quadrant: number,
  isReviewed?: boolean,
  isDisabled?: boolean,
  statsMap: {[key:string]:number | string},
  stats: Array<{key:string, val:number | string}>,
  isModified?: boolean,
  quality: number,
  questions: {
    label: string,
    [key:string]: any,
  }[],
}

export enum TestFormConstructionMethod {
  LINEAR = 'LINEAR',
  TLOFT = 'TLOFT',
  MSCAT = 'MSCAT',
}

export const QUESTION_WORDING_OPTS = [
  'title_question',
  'title_page'
]

export enum ENextBtnOpt {
  NEXT = 'NEXT',
  NEXT_PREV = 'NEXT_PREV'
}

export const NEXT_BTN_OPTS = [
  {id: ENextBtnOpt.NEXT, caption: 'Next button after question'},
  {id: ENextBtnOpt.NEXT_PREV, caption: 'Next/Prev button overlay'}
]

export interface IPanelAssemblyConfig {
  startingModule: string,
  allowNumCorrect?: boolean,
  allowShuffle?: boolean,
  numStages: number,
  uniqueSpecificExpectationCode?: boolean,
  maxDifficultyLevel?:number,
  easyStartSettings: {
    numberOfItems: number,
    prop: string,
    isInverse: boolean,
  },
  allModules: Array<{
    id: string,
    stageNumber: number,
    difficultyLevel?: number,
    item_count: number,
    difficulty_range: number[], 
    item_difficulty_range?: number[], 
    preambleQuestionLabel?: string,
    preambleLabelList?: string[],
    postambleLabelList?: string[],
    sidebarThumbnailEn?: string,
    sidebarThumbnailFr?: string
  }>,
  routingRules: {
    [key:string]: Array<IRoutingRule>
  },
  constraints: Array<{
    when: Array<{
      moduleProp: string,
      comparison: string,
      value: number
    }>,
    require: Array<{
      prop: string,
      method: string,
      comparison: string,
      config: {
        value?:string,
        count?:number,
      }
    }>
  }>
}

export interface IPanelModulesConfig {
  id: number,
  // overallAgreement?: number,
  dateCreated: string,
  modules: {
    moduleId: number,
    itemLabels: string[],
    itemIds?: number[],
    // difficulty?:number,
  }[]
}

export interface IAssessmentToolbarOptions {
  zoomIn: boolean;
  zoomOut: boolean;
  lineReader: boolean;
  hiContrast: boolean;
  toggleEditor: boolean;
  highlighter: boolean;
  eraser: boolean;
  notepad: boolean;
  infoButton: boolean;
}

export enum ReportOptions{
  SCORE =  "score",
  CHECKMARK = "checkmark",
  STUDENT_PEN = "student_pen",
  LEGEND = "legend"
}

export interface IAssessmentReportOptions {
  hideScore?: boolean;
  showCheckmark?: boolean;
  hideStudentPen?: boolean;
  hideLegend?: boolean;
}

export interface ISectionItemsMap { 
  [key:number]: ISectionItems 
}
export interface ISectionItems {
  questions: {
    label: string,
    id?: number,
  }[]
}
export interface IAssessmentParameterViewFilter {
  name: string;
  filter: any
}

export interface IAssessmentFrameworkDetail extends IAssessmentFrameworkDef{
  notes?: string;
  cutScore?: any;
  numTestTakers?: number,
  parititionIdAI: number,
  quadrantIdAI: number,
  quadrants: IQuadrant[],
  preservedMetaParams?: string[],
  sectionItems?: ISectionItemsMap,
  panelAssembly?: IPanelAssemblyConfig,
  panels?: IPanelModulesConfig[],
  quadrantItems?: {
    id: number,
    questions: {
      label: string
    }[],
    numTestlets?:number,
    exposureMin?:number,
    exposureMax?:number,
    numItemsUsed?:number,
    description:string,
    minQRequired:number,
    crossLinkedItems:{
      label:string, 
      quadrantsCrossLinks: any[]
    }[],
    // [key:string]: any,
  }[],
  testlets?: IQuadrantTestlet[],
  assembledPanels?: {
    id: number,
    testletIds: number[],
    lang: string,
    sections: any[],
    isExcluded?: boolean,
  }[],
  testletCounter?: number,
  testForms?: ITestDesignPayload[],
  partitions? : IAssessmentPartition[],
  estimatedTestTakers? : number,
  rubricDownloadLink? : string,
  assessmentName? : string,
  msgFinalSubmission? : string,
  msgResultsPage? : string,
  customResultPage? : string;
  customResultPageTitle?: string;
  customNavbarResultIntroPageTitleEN?: string;
  customNavbarResultIntroPageTitleFR?: string;
  isCustomResultPageNote?: boolean;
  customResultPageNoteEN?: string;
  customResultPageNoteFR?: string;
  msgPathWarnOverride? : string,
  msgPathCnfmOverride? : string,
  msgPathCnfmCancelOverride? : string,
  msgPathCnfmProceedOverride? : string,
  isExposureComputed? : boolean,
  isResultsDetailDisabled? : boolean,
  helpPageId? : number,
  referenceDocumentPages? : {itemId:number, caption:string}[],
  isTimerDisabled? : boolean,
  isSpentTimerDisabled?: boolean;
  recommendedTime?: string;
  isOrale?: boolean;
  extraTime?: string;
  isUnreadyExcluded? : boolean,
  tags? : {slug:string}[],
  useQuestionLabel? : boolean,
  questionWordSlug? : string,
  useSectionCaptions? : boolean,
  testFormType? : TestFormConstructionMethod,
  testletStatCol? : Array<{
    isShown:boolean,
    key: string,
  }>,
  primaryDimensions:IAssessmentFrameworkDimensionDetail[],
  secondaryDimensions: IAssessmentFrameworkDimensionDetail[],
  filters?: IAssessmentParameterViewFilter[]
  toolbarOptions?: IAssessmentToolbarOptions;
  reportOptions?: IAssessmentReportOptions;
  styleProfile?: EStyleProfile;
  nextButtonOpt?: ENextBtnOpt;
  showQHeader?: boolean;
  showDocumentsSplitScreen?: boolean;
  sidebarThumbnailEn?: string;
  sidebarThumbnailFr?: string;
  isThumbnailFullWidth?: boolean;
}
export interface IRoutingRule { 
  module: string, 
  minTheta:number,
  maxTheta:number,
  minPropC: number, // >=
  maxPropC: number, // <
}

export interface IAssessmentPartition {
  id: number,
  description: string,
  preambleQuestionLabel?: string,
  preambleLabelList?: string[],
  postambleLabelList?: string[],
  mapMetaItemId?: number,
  infoCaption?: string,
  submissionText?: string,
  notepadText?: string,
  isShuffled?: boolean,
  isCalculatorEnabled?: boolean,
  isNotepadDisabled?: boolean,
  isNotepadEnabled?: boolean,
  isFormulaSheetEnabled?: boolean,
  areQuestionsSkippable?: boolean,
  isTimeLimit?: boolean,
  isConditional?: boolean,
  timeLimitMinutes?:number,
  conditionOnItem?:string,
  conditionOnOption?:string,
  disableScoring?: boolean;
  msgReqFill?: string;
  orderByParam?: string;
  disableFlagging?: boolean;
  disableLeftBar?: boolean;
  preambleThumbnail?: string,
  preambleThumbnailSelected?: string,
  preambleThumbnailText?: string,
  customSectionPopup? : boolean;
  customSectionPopupSlug?: string;
  sidebarThumbnailEn?: string;
  sidebarThumbnailFr?: string;
}

export enum DimensionType {
  SELECT = 'select-sub',
  BINARY = 'binary',
  NUMERIC = 'numeric',
  LABEL = 'label',
  TEXT = 'text',
  IMAGE = 'image',
  AUDIO = 'audio',
  COORD = 'coordinate',
  VOICE = 'voice',
} 
;

export interface IAssessmentFrameworkDimensionDetail {
  name: string, // ex: Big Ideas
  code: string, // ex: D1
  type: DimensionType,
  color?: string,
  isHidden?: boolean,
  key?: string,
  config: {
    special?: {
      [key:string]: boolean,
    },
    tags? : Array<{
      name: string, // ex: Number Sense
      code: string, // ex: NS
    }>,
  }
}
