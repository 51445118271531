<div class="canvas-container" [style.filter]="getFilter()" [style.padding.px]="getPadding()"
>
  <div [ngStyle]="getStyle()">
    <div 
        style="border:1px solid #ccc; background-color: #f1f1f1; background-repeat: no-repeat;background-size: 100% 100%;" 
        [ngStyle]="{'width.em':element.width*element.scale/100,
        'height.em':element.height*element.scale/100,
        border: (this.element.isFrameHidden) ? 'none' : '1px solid #ccc' }"
        [style.background-image]="getBackImg()"
        [style.background-color]="element.frame ? element.frame.backgroundColor : '' "
    >
      <div *ngIf="currentPage && currentPage.displayList" [ngStyle]="{'font-size.em':element.scale/100 }">
        <ng-container *ngFor="let contentElement of currentPage.displayList" >
          <div 
            *ngIf="!contentElement.isDisabled"
            style="position:absolute;"
            [ngStyle]="{'left.em':contentElement.x, 'top.em':contentElement.y, 'width.em':contentElement.width, 'height.em':contentElement.height }"
          >
            <div *ngIf="isContentElementBookmark(contentElement)" 
              class="bookmark-element" 
              [class.is-clicked]="contentElement===clickedBookMark" 
              [class.is-active]="isActiveBookmark(contentElement)" 
              [class.is-shown]="element.showBookmarks"
            ></div>
            <div 
              *ngIf="isContentElementText(contentElement)" 
              [ngStyle]="getTextStyle(contentElement)" 
              [class.is-transparent-text]="contentElement.isInvisible"
            >
              <element-render-text [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-text>
            </div>
            <div *ngIf="isContentElementDefault(contentElement)">
              <element-render 
                [contentElement]="contentElement" 
                [questionState]="questionState"
                [isLocked]="isLocked"
                [questionPubSub]="questionPubSub"
              ></element-render>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="counter-container" *ngIf="isPaginationActive()">
      <div class="canvas-pagination-strip" aria-label="pagination">
        <button class="button" [disabled]="isOnFirstPage()" (click)="gotoPrevPage()">
          <span class="icon"><i class="fas fa-caret-left" ></i></span>
          <span> <tra slug="osslt_prev_page"></tra> </span>
        </button>
        
        <div class="field has-addons">
          <p *ngFor="let page of element.pages; index as i">
            <button
              class="button page-controls" 
              [class.is-info]="isCurrentPage(i)"
              [attr.aria-label]="'Page ' + (i + 1)" 
              aria-current="page"
              (click)="gotoPage(i)"
            >
              {{i + 1}}
            </button>
          </p>
        </div>

        <button class="button" [disabled]="isOnLastPage()" (click)="gotoNextPage()">
          <span> <tra slug="osslt_next_page"></tra> </span>
          <span class="icon"><i class="fas fa-caret-right" ></i></span>
        </button>
      </div>
    </div>
  </div>
</div>
