import { Injectable } from '@angular/core';
import { initMappedList, IMappedList } from './data/util';
import { STUDENT_G9_COURSES, STUDENT_G9_COURSES_SIMPLE, G9_COURSES } from './data/constants';
import { IIdentifiedEntry, IDataDefinition } from './data/types';
import { IStudentAccount, IStudentTestSession } from './data/types';
import { IClassroomArchivedTeachers, IUserInfo, IClassroomSlot } from '../../app/ui-teacher/data/types';
import { IClassroom, ISemester, ISession} from "./data/types"
import { generateEntries, randArrEntry, randInt, randId, coinFlip, randDate, IAvailableSession } from "../ui-testadmin/demo-data.service";
import { randomIdentity } from "../constants/fakenames";
import { LangService } from '../core/lang.service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { DATA_MAPPING_EQAO_G9_STUDENT, ICheckMarkMapping, IDataMappingG9Student } from './data/mappings/eqao-g9';
import { BC_SAMPLE_SCHOOL_FSA } from './data/sample-data/school';
import { WhitelabelService } from "../../app/domain/whitelabel.service";
import { ClassFilterId } from './my-school.service';

export const generateAccessCode = (len: number) => {
  let result = '';
  const characters = 'ABCDEFGHJKLMNPQRTUVWXYZ12346789';
  const charactersLength = characters.length;
  for (var i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export const hyphenateAccessCode = (accessCode: string) => {
  if (!accessCode) { return accessCode; }
  let result = '';
  const hyphenInterval = 4;
  for (var i = 0; i < accessCode.length; i++) {
    if (i > 0 && (i % hyphenInterval == 0)) {
      result += '-'
    }
    result += accessCode.charAt(i);
  }
  return result;
}

const G9_SLUG_TO_CAPTION = {
  G9_SAMPLE: 'g9_sample_test',
  G9_OPERATIONAL: 'g9_assess_math',
  OSSLT_SAMPLE: 'lbl_osslt_practice_test',
  OSSLT_OPERATIONAL: 'lbl_osslt_test',
}

export const overlapVariables = [
  'first_name',         'last_name',                'eqao_student_school_enrol_type', 'eqao_student_gov_id',     'SASN',
  'date_of_birth',      'eqao_gender',              'eqao_date_entered_school',       'eqao_date_entered_board', 'eqao_indigenous_type',
  'eqao_first_language','eqao_enrolled_ontario',    'eqao_out_province_residence',    'eqao_status_in_canada',   'eqao_time_in_canada',
  'eqao_refugee',       'eqao_born_outside_canada', 'eqao_is_g9',                          'eqao_is_g10',            
 ]
 
@Injectable({
  providedIn: 'root'
})

export class G9DemoDataService {

  
  teachers: IMappedList<IIdentifiedEntry>;
  schoolAdminStudents: IMappedList<IStudentAccount>;
  classOptions: IMappedList<IDataDefinition>;
  classrooms: IClassroom[];
  semesters: IMappedList<ISemester>;
  teacherClassrooms: IMappedList<any>;
  schoolData: any;
  assessments:IMappedList<ISession>;
  private studentMappings = DATA_MAPPING_EQAO_G9_STUDENT;
  public checkMarkMapping: ICheckMarkMapping = {};
  public g10PropCheckMarkMapping: ICheckMarkMapping = {};

  private classroomMappings = [
    { source: 'id', target: 'id' },
    { source: 'name', target: 'class_code' },
    { source: 'teacher', target: 'educator' },
    { source: 'teacher_uid', target: 'teacher_uid' },
    { source: 'semester_id', target: 'semester' },
    { source: 'semester_foreign_id', target: 'semester_foreign_id' },
    { source: 'num_students', target: 'students' },
    { source: 'group_type', target: 'course_type' },
    { source: 'is_grouping', target: 'is_grouping' },
  ];
  private schoolDistrictGroupId: number
  activeSessionsCount:number;
  scheduledSessionsCount:number;
  g9ActiveSessionsCount:number;
  g9ScheduledSessionsCount:number;
  ossltActiveSessionsCount:number;
  ossltScheduledSessionsCount:number;
  attempts: IMappedList<IIdentifiedEntry>;
  testWindows: any[];
  schoolDistrict:{foreign_id?: string} = {};
  constructor(
    private lang: LangService,
  ) {
    // const res = require("./data/sample-response.json");
    // this.init(BC_SAMPLE_SCHOOL_FSA)
  }

  initCheckmarkMappings() {
    this.studentMappings.forEach((studentMapping: IDataMappingG9Student) => {
      this.checkMarkMapping[studentMapping.target] = studentMapping.checkMarkMap;

      const isG10 = studentMapping.key_namespace === 'eqao_sdc_g10';
      let prefix = isG10 ? '_g10_' : '';
      this.g10PropCheckMarkMapping[`${prefix}${studentMapping.target}`] = studentMapping.checkMarkMap;
    })
  }

  init(res: any) {

    this.initCheckmarkMappings();
    
    
    const studentData = res.students || [];
    const teacherData = res.teachers || [];
    const semesterData = res.school_semesters || [];
    const sessionData = res.sessions || [];
    const classesData: any[] = res.classes || [];
    const testAttempts = res.test_attempts || [];
    const studentSubmissions = res.student_submissions || [];
    const studentTestAttempts: any[] = res.student_testAttempts || []
    const SDC_conflicts_g9 = res.SDC_conflicts_g9 ||[]
    const SDC_conflicts_g10 = res.SDC_conflicts_g10 ||[]
    const mapBy = (arr, prop) => {
      const m = new Map();
      arr.forEach(entry => {
        m.set(entry[prop], entry)
      })
      return m;
    }

    const metaKeys = [];


    // access_code: "ANTW"
    // caption: null
    // date_time_start: "2020-11-05T01:45:18.000Z"
    // school_class_id: 9
    // slug: "G9_SAMPLE"
    // test_session_id: 584


    //creates map from student id value to student data
    const studentByUid = mapBy(studentData, 'id');
    const teachersByUid = mapBy(teacherData, 'id');
    const classesByGroupId = mapBy(classesData, 'group_id');
    const classesByClassId = mapBy(classesData, 'id');
    const teacherByClassGroupId = new Map();

    res.classes_sessions = res.classes_sessions || [];
    res.classes_closed_sessions = res.classes_closed_sessions || [];
    res.classes_teachers = res.classes_teachers || [];
    res.student_meta = res.student_meta || [];
    res.classes_students = res.classes_students || [];

    classesData.forEach(classroom => {
      classroom.when = (classroom.notes || '').split('When:').join('')
      classroom.students = []
      classroom.openAssessments = []
      classroom.recentAssessments = []
      classroom.scheduledAssessments = []
    })

    res.classes_sessions.forEach(session => {
      const classroom = classesByClassId.get(session.school_class_id);
      let currDate = new Date();
      let sessionDate = new Date(session.date_time_start)
      if (currDate.getTime() < sessionDate.getTime()) {
        //scheduled for the future
        classroom.scheduledAssessments.push(session)
      }
      else{
        classroom.openAssessments.push(session);
      }
    });

    res.classes_closed_sessions.forEach(session => {
      const classroom = classesByClassId.get(session.school_class_id);
      const closeTime = new Date(session.closed_on);
      const openTime = new Date(session.date_time_start);
      if(closeTime.getTime() > openTime.getTime()){
        classroom.recentAssessments.push(session);
      }
    })

    res.student_meta.forEach(meta => {
        const student = studentByUid.get(meta.uid);
        student[meta.key_namespace] = student[meta.key_namespace] || {}
        student[meta.key_namespace][meta.key] = meta.value;
        //student[meta.key] = meta.value;
        //metaKeys.push(meta.key)
    })

    res.classes_teachers.forEach(entry => {
      const teacher = teachersByUid.get(entry.uid)
      const classroom = classesByGroupId.get(entry.group_id);
      if(classroom){
        classroom.teacher_uid = entry.uid;
      }  
      if (teacher) {
        teacher.classes = teacher.classes || [];
        teacher.semesters = teacher.semesters || [];
        if(classroom){
          classroom.teacher = teacher.first_name + ' ' + teacher.last_name;
          teacher.classes.push(classroom.name)
          teacher.semesters.push(classroom.semester_id)
        }
        teacherByClassGroupId.set(entry.group_id, teacher)
      }
    })

    // Array to keep track of teachers and their students
    const teachersWithStudents: {
      teacher: any,
      students: any[]
    }[] = [];

    // Helper function to find one element in the array
    const firstOrDefault = (inputArray: any[], condition: any): any => {
      if (inputArray == null || inputArray.length == 0) {
        return null;
      }
      for(let i = 0; i < inputArray.length; i++) {
        const el = inputArray[i];
        if (condition(el) === true) {
          return el;
        }
      }
      return null;
    };

    res.classes_students.forEach(entry => {
      const student = studentByUid.get(entry.uid)
      const teacher = teacherByClassGroupId.get(entry.group_id)
      const classroom = classesByGroupId.get(entry.group_id);
      if (student) {
        //student.class_code = classroom.name;
        student.class_code = student.class_code||[];
        student.class_code.push(classroom.name)
        student.teacher = classroom.teacher;
        student.classroomIds = student.classroomIds || []
        student.classroomIds.push(entry.group_id);
        student.semester_id = classroom.semester_id
        // classroom.semesters = classroom.semesters || []
        // classroom.semesters.push(student.Program); //This doesn't seem right
        // classroom.course_id = classroom.course_ids || []
        classroom.courses = classroom.courses || []
        const relevantCourse = STUDENT_G9_COURSES.map[student.Program];
        if (relevantCourse) {
          classroom.courses.push(this.lang.tra(relevantCourse.course));
        }
        classroom.course_id = student.Program;
        classroom.num_students = classroom.num_students || 0;
        classroom.num_students++;
        if (teacher) {
          // Track the teachers with their students
          const teacherWithStudents = firstOrDefault(teachersWithStudents, (ts: any) => ts.teacher && ts.teacher.id === teacher.id);
          // New teacher, let's add it to the list
          if (!teacherWithStudents) {
            teachersWithStudents.push({
              teacher: teacher,
              students: [student]
            })
          } else {
            // Check for students duplication to avoid extera student count BUG# 
            const teachersStudent = firstOrDefault(teacherWithStudents.students, (st: any) => st.id === student.id);
            if (!teachersStudent) {
              teacherWithStudents.students.push(student);
            }
          }
          // teacher.num_students = teacher.num_students || 0;
          // teacher.num_students++;
        }
      }
    });

    // Fix num_students on teacher object
    teachersWithStudents.forEach((ts: any) => {
      if (ts.teacher) {
        ts.teacher.num_students = ts.students ? ts.students.length : 0
      }
    });
  

    classesData.forEach(classroom => {
      classroom.courses = _.uniq(classroom.courses).join(', ')
    })

    teacherData.forEach(teacher => {
      teacher.classes = teacher.classes || []
      teacher.classes = teacher.classes.join(', ')
      //teacher.semester = 'test'
    })

    const studentsList = [];

    const submissionMap = {}

    // todo: split up is_submitted by assessment
    const operationalSubmRef = [
      // {filter: ClassFilterId.G9, slug: 'G9_OPERATIONAL'},
      {filter: ClassFilterId.OSSLT, slug: 'OSSLT_OPERATIONAL'},
    ]
    const operationalSubmMap = new Map()
    operationalSubmRef.forEach(record => {
      submissionMap[record.filter] = {};
      operationalSubmMap.set(record.slug, record.filter)
    });
    studentSubmissions.forEach(stuSubmRecord =>{
      if(stuSubmRecord){ // .is_submitted == 1
        const submFilter = operationalSubmMap.get(stuSubmRecord.slug);
        if (submFilter){
          submissionMap[submFilter][stuSubmRecord.id] = stuSubmRecord
        }
      }
    });

    const getSubmissionStatusStr = (state:boolean) => {
      if (state){
        return '1'; // this.lang.tra('lbl_status_submitted');
      }
      else {
        return '#'; // this.lang.tra('lbl_no')
      }
    }

    for (let student of studentByUid.values()) {
      const studentNormalized = this.apiToClientPayloadStudent(student);
      operationalSubmRef.forEach(record => {
        let submState = submissionMap[record.filter][student.id]
        // todo: split up is_submitted by assessment
        studentNormalized.is_submitted_state = submState;
        studentNormalized.is_submitted = getSubmissionStatusStr(!!submState);
        if (submState){
          // todo: other data models to be updated when g9 included here
          // console.log('studentNormalized submission')
          studentNormalized.osslt__is_submitted = '1'; // "Yes";
          if (submState.overall == 1){
            studentNormalized.osslt__is_success = '1'; // 'Yes'
          }
          else if (submState.is_data_insufficient == 1){
            studentNormalized.osslt__is_success = '2'; // 'Insuf.'
          }
          else {
            studentNormalized.osslt__is_success = '0'; // 'Not Yet'
          }          
        }
        else{
          studentNormalized.osslt__is_success = '#';
          studentNormalized.osslt__is_submitted = '#'; // no 
        }
      });
      studentNormalized.testAttempts = studentTestAttempts.filter(attempt => attempt.uid == studentNormalized.id)
      studentNormalized.haveOSSLTSubmitted = '#';
      const ossltSlug = 'OSSLT_OPERATIONAL';
      const haveOSSLTSubmitted = studentNormalized.testAttempts.find(ta => ta.slug === ossltSlug && +ta.is_submitted === 1)
      if(haveOSSLTSubmitted!=undefined){
        studentNormalized.haveOSSLTSubmitted = '1';
      }
      studentNormalized.haveG9Submitted = '#';
      const g9Slug = 'G9_OPERATIONAL';
      const haveG9Submitted = studentNormalized.testAttempts.find(ta => ta.slug === g9Slug && +ta.is_submitted === 1)
      if(haveG9Submitted!=undefined){
        studentNormalized.haveG9Submitted = '1';
      } 

      studentsList.push(studentNormalized);

      if (student.classroomIds) {
        student.classroomIds.forEach(classGroupId => {
          const classroom = classesByGroupId.get(classGroupId);
          classroom.students.push(studentNormalized)
        })
      }
    }

    this.schoolAdminStudents = initMappedList(studentsList);
    const sampleStudents = this.schoolAdminStudents.list;
    const nameToId = new Map();
    const classOptionsList = classesData.map((classroom, index) => {
      const id = classroom.id;
      nameToId.set(classroom.name, id);
      return { id, label: classroom.name, group_type: classroom.group_type};
    });
    for (let student of sampleStudents) {
      //student.eqao_g9_class_code_label = student.eqao_g9_class_code;
      //student.eqao_g9_class_code = nameToId.get(student.eqao_g9_class_code);

      studentByUid.get(student.id).classroomIds.forEach(classID => {
        const studentClass = classesData.find(theClass => theClass.group_id == classID)
        if(studentClass.group_type == 'EQAO_G9'){
          student.eqao_g9_class_code = studentClass.id;
          student.eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'EQAO_G10'){
          student._g10_eqao_g9_class_code = studentClass.id;
          student._g10_eqao_g9_class_code_label = studentClass.name
        }
      });
    }
    this.classOptions = initMappedList(classOptionsList);
    const teachersList = [];
    for (let teacher of teacherData) {
      let time = moment.tz('2020-10-28 17:00', moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      let time2 = moment.tz('2020-11-07 17:00', moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      let secret_key;
      let invit_id
      if (teacher.created_on) {
        time = moment.tz(teacher.created_on, moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      }
      if (teacher.expire_on) {
        time2 = moment.tz(teacher.expire_on, moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
      }

      const name = teacher.first_name + " " + teacher.last_name;
      teachersList.push({
        id: teacher.id,
        label: name,
        invigilator: name,
        // firstName: name.trim().split(" ")[0],
        // lastName: name.trim().split(" ")[1],
        firstName: teacher.first_name,
        lastName: teacher.last_name,
        email: teacher.contact_email,
        invit_id: teacher.invit_id || null,
        secret_key: teacher.secret_key || null,
        invit_email: teacher.invit_email || null,
        isConfirmed: !!teacher.contact_email,
        classCode: teacher.classes,
        semester:teacher.semesters,
        semester_labels: (teacher.semesters && teacher.semesters.lengh>0) ? teacher.semesters.map(semester => semesterData.find(semester2 => semester2.id == semester).name):[],
        students: teacher.num_students || 0,
        startTime: time, //This and below are still sample data
        endTime: time2
      });
    }

    this.teachers = initMappedList(teachersList);


    // console.log('classesData', classesData)
    this.semesters = initMappedList(semesterData.map(semester => {
      return {
        id: semester.id,
        foreign_scope_id: semester.foreign_scope_id,
        foreign_id: semester.foreign_id,
        label: semester.name,
        testWindowId: semester.test_window_id
      }
    }))
    this.attempts = initMappedList(testAttempts.map(attempt => {
      return {
        submissions: attempt.submissions,
        submitted_test_session_id: attempt.submitted_test_session_id,
      }
    }))

    if(!Array.isArray(sessionData)){
      this.activeSessionsCount = 0;
      this.scheduledSessionsCount = 0;
      this.g9ActiveSessionsCount = 0;
      this.g9ScheduledSessionsCount = 0;
      this.ossltActiveSessionsCount = 0;
      this.ossltScheduledSessionsCount = 0;
      this.assessments = initMappedList(sessionData.school_sessions.map(session => {
        return {
          id: session.test_session_id,
          school_name:null,
          invigilator: `${session.first_name} ${session.last_name}`,
          classroom_id: session.school_class_id,
          firstName: session.first_name,
          lastName: session.last_name,
          description: '',
          slug:session.slug,
          classCode: session.name,
          students: session.num_students,
          startTime: this.getDate(session.date_time_start),
          endTime: '',
          closed_on: this.getDate(session.closed_on),
          isclosed: (session.is_closed == 1) ? true: false,
          status: this.returnSessionStatus(session) ? 'active' : 'pending',
          submissions: this.getSubmissionStatus(this.attempts.list, session),
          //submissions: 0,
          isConfirmed: coinFlip()
        }
      })) 
    }
    
    this.teacherClassrooms = initMappedList(classesData.map(classroom => {
      classroom.scheduledAssessments.forEach(session => this.processSessionEntry(session));
      classroom.openAssessments.forEach(session => this.processSessionEntry(session));
      classroom.recentAssessments.forEach(session => this.processSessionEntry(session));
      
      return {
        id: classroom.id,
        name: classroom.name,
        classCode: hyphenateAccessCode(classroom.access_code),
        owner: '',
        isAssigned: !(classroom.is_grouping == 1),
        group_id: classroom.group_id,
        curricShort: classroom.group_type || 'EQAO_G9',
        // curricShort: `${this.lang.tra('txt_g9_math')} (${classroom.courses})`,
        enableClassListingByCC: true,
        currentStudents: initMappedList(classroom.students.map(student => {
          return {
            ...student,
            uid: student.id, 
            displayName: [student.first_name, student.last_name].join(' ')
          }
        })),
        currentTeachers: [],
        openAssessments: classroom.openAssessments,
        recentAssessments: classroom.recentAssessments,
        scheduledAssessments: classroom.scheduledAssessments,
        timeCreated: '--',
        timeLastTouched: '--',
      }
    }));

    this.schoolData = res.school[0];
    this.schoolDistrict = {
      foreign_id: this.schoolData.sd_foreign_id
      //foreign_id:res.schl_dist[0].foreign_id
    };
    this.classrooms = [];
    for (let classroom of classesData) {
      this.classrooms.push(this.apiToClientPayloadClass(classroom));
    }

    this.schoolAdminStudents.list.forEach(student => {
      const SDC_conflict_g9 =  SDC_conflicts_g9.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g9 != undefined && SDC_conflict_g9.compare_result!='[]'){
        student.SDC_conflict_g9 = SDC_conflict_g9
      } 
      const SDC_conflict_g10 =  SDC_conflicts_g10.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g10 != undefined && SDC_conflict_g10.compare_result!='[]'){
        student.SDC_conflict_g10 = SDC_conflict_g10
      }
      this.processNewStudentAccount(student) 
    });

    this.testWindows = res.test_windows;
  }

  processNewStudentAccount(student: IStudentAccount) {
    student['_g10_NonParticipationStatus_deferred'] = "#"
    student['_g10_NonParticipationStatus_exempted'] = "#"
    student['_g10_NonParticipationStatus_osslc'] = "#"
    if(student['_g10_NonParticipationStatus'] == '1'){
      student['_g10_NonParticipationStatus_exempted'] = '1';
    }
    if(student['_g10_NonParticipationStatus'] == '2'){
      student['_g10_NonParticipationStatus_deferred'] = '1';
    }
    if(student['_g10_NonParticipationStatus'] == '3'){
      student['_g10_NonParticipationStatus_osslc'] ='1'; 
    }
    student['_g10_eqao_acc_assistive_tech_1_chrome'] = '#';
    student['_g10_eqao_acc_assistive_tech_1_other'] = '#';
    if(student['_g10_eqao_acc_assistive_tech'] == '2'){
      student['_g10_eqao_acc_assistive_tech_1_chrome'] = '1';
      student['_g10_eqao_acc_assistive_tech_1_other'] = '#';
    }
    if(student['_g10_eqao_acc_assistive_tech'] == '1'){
      student['_g10_eqao_acc_assistive_tech_1_chrome'] = '#';
      student['_g10_eqao_acc_assistive_tech_1_other'] = '1';
    }
    student['eqao_acc_assistive_tech_1_chrome'] = '#';
    student['eqao_acc_assistive_tech_1_other'] = '#';
    if(student['eqao_acc_assistive_tech'] == '2'){
      student['eqao_acc_assistive_tech_1_chrome'] = '1';
      student['eqao_acc_assistive_tech_1_other'] = '#';
    }
    if(student['eqao_acc_assistive_tech'] == '1'){
      student['eqao_acc_assistive_tech_1_chrome'] = '#';
      student['eqao_acc_assistive_tech_1_other'] = '1';
    }  
  }



  public processSessionEntry(session: any) {
    const mStart = moment.tz(session.date_time_start, moment.tz.guess());
    session.timeDirectStart = mStart.format(this.lang.tra('datefmt_day_month'));
    session.dateTimeStartLong = mStart.format(this.lang.tra('datefmt_sentence'));
    session.hourDirectStart = mStart.format(this.lang.tra('timefmt_hour_time'));
    if(session.closed_on) {
      const mClosed = moment.tz(session.closed_on, moment.tz.guess());
      session.timeDirectClose = mClosed.format(this.lang.tra('datefmt_day_month'));
    }

    session.name = this.lang.tra(G9_SLUG_TO_CAPTION[session.slug] || 'New Assessment');
    return session;
  }

  getAPITargetMapping(sourceMapping: string): string {
    let targetMapping = '';
    let mapping = this.studentMappings.find(mapping => mapping.source === sourceMapping);
    if (mapping) {
      targetMapping = mapping.target;
    }
    return targetMapping;
  }

  generateStudentInvigilationRecord(studentAccount) {
    // console.log(JSON.stringify(studentAccount))
    // studentAccount.status = {};
    return {
      studentAccount,
      session_a: { // this should be an array
        is_session_active: true,
      },
    }
  }
  getSubmissionStatus(testAttempts, session) {

    const attempt = testAttempts.find(attempt => attempt.submitted_test_session_id === session.test_session_id);
    if(!attempt){
      return 0;
    }
    return attempt.submissions;

    /* const states = session_states.find(state => { return state.test_session_id === session.test_session_id }).states.studentStates;
    for (const [key, value] of Object.entries(states)) {
      for (const [innerKey, innerValue] of Object.entries(value)) {
        if (innerKey === 'submitted_test_session_id' && innerValue === session.test_session_id) {
          submissions.push(key)
        }
      }
    } */
  }
  getDate(dateTime) {
    const mStart = moment.tz(dateTime, moment.tz.guess());
    let formatted = mStart.format(this.lang.tra('datefmt_sentence'));
    formatted = (formatted === 'Invalid date') ? this.lang.tra('sa_invalid_date') : formatted;
    return formatted;
  }
  returnSessionStatus(session) {
    let currDate = new Date();
    let sessionDate = new Date(session.date_time_start);
    console.log(session);
    console.log(session.slug);
    if (currDate.getTime() > sessionDate.getTime()) {
      this.activeSessionsCount++;
      if (session.slug.toUpperCase().indexOf("G9") > -1)    this.g9ActiveSessionsCount++;
      if (session.slug.toUpperCase().indexOf("OSSLT") > -1) this.ossltActiveSessionsCount++;
      return true;
    }
    this.scheduledSessionsCount++;
    if (session.slug.toUpperCase().indexOf("G9") > -1)    this.g9ScheduledSessionsCount++;
    if (session.slug.toUpperCase().indexOf("OSSLT") > -1) this.ossltScheduledSessionsCount++;
    return false;
  }
  public timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours > 0) {
      return rhours + "hr " + rminutes + "min";
    }
    return rminutes + "min";
  }

  getStudentsByClassroomId(classroomId: string): IMappedList<IStudentAccount> {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom.currentStudents;
    }
  }

  getSessionByClassroomId(classroomId: string, sessionId: string) {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom && classroom.openAssessments) {
      for (let i = 0; i < classroom.openAssessments.length; i++) {
        const session = classroom.openAssessments[i];
        if (('' + session.test_session_id) === ('' + sessionId)) {
          return session;
        }
      }
      for (let i = 0; i < classroom.scheduledAssessments.length; i++) {
        const session = classroom.scheduledAssessments[i];
        if (('' + session.test_session_id) === ('' + sessionId)) {
          return session;
        }
      }
    }

    return {};
  }

  getClassroomNameById(classroomId: string) {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom.name;
    }
    return '';
  }
  getClassroomById(classroomId: string){
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom;
    }
    return '';

  }

  apiToClientPayloadClass(fromAPI: any): IClassroom {
    let newPayload: any = {};

    this.classroomMappings.forEach((mapping: { source: string, target: string }) => {
      let val = _.get(fromAPI, mapping.source);
      newPayload[mapping.target] = val;
    });

    newPayload.course_type = newPayload.course_type || 'EQAO_G9';
    newPayload.is_grouping = +(newPayload.is_grouping || 0);
    
    const courseId = fromAPI.course_id;
    const course = STUDENT_G9_COURSES.map[courseId];
    if (course) {
      const isInPerson = course.inPerson;
      const isRemote = course.remote;
      const courseType = course.course;
      const simpleCourseId = STUDENT_G9_COURSES_SIMPLE.list.filter(item => item.course === courseType)[0].id;
      newPayload.course = simpleCourseId;
      newPayload.remote_courses_sdc_student_rem_inp_1 = isInPerson;
      newPayload.remote_courses_sdc_student_rem_inp_2 = isRemote;
    }
    newPayload.openAssessments = []; //Here and below is still unchanged from the sample data
    newPayload.recentAssessments = [];
    newPayload.onboarding = 0;
    newPayload.assessment = 0;
    return newPayload;
  }

  //Use this when saving the data back to the API
  clientToApiPayloadClass(fromClient: IClassroom): any {
    let newPayload: any = {};

    this.classroomMappings.forEach((mapping: { source: string, target: string }) => {
      _.set(newPayload, mapping.source, fromClient[mapping.target]);
    });

    const simpleCourse = STUDENT_G9_COURSES_SIMPLE.map[fromClient.course];
    const courseType: G9_COURSES = simpleCourse.course;
    const complexCourse = STUDENT_G9_COURSES.list.filter((course) => {
      return course.course === courseType &&
        course.inPerson === fromClient.remote_courses_sdc_student_rem_inp_1 &&
        course.remote === fromClient.remote_courses_sdc_student_rem_inp_2;
    })[0];
    newPayload.course_id = complexCourse.id;

    return newPayload;
  }

  apiToClientPayloadStudent(fromAPI: any): IStudentAccount {
    let newPayload: IStudentAccount = { id: fromAPI.id, first_name: fromAPI.first_name, last_name: fromAPI.last_name}; //id is only here because it is required for IStudentAccount (not sure why), but we only use uid
    this.studentMappings.forEach((mapping: { source: string, target: string, key_namespace:string}) => {
      if(mapping.key_namespace == 'eqao_sdc'){
        if(mapping.source == 'StudentOEN'){ //temp solution
          let val = _.get(fromAPI['eqao_sdc_g10'], mapping.source) || _.get(fromAPI['eqao_sdc'], mapping.source);
          newPayload[mapping.target] = val;
          return;
        }
        let val = _.get(fromAPI[mapping.key_namespace], mapping.source);
        newPayload[mapping.target] = val;
      }else if(mapping.key_namespace == 'eqao_sdc_g10') {
        let val = _.get(fromAPI[mapping.key_namespace], mapping.source);
        newPayload['_g10_'+mapping.target] = val;
      }
    });
    newPayload.uid = fromAPI.id;
    newPayload.first_name = fromAPI.first_name;
    newPayload.last_name = fromAPI.last_name;
    if (!newPayload['eqao_is_g9'] && !newPayload['eqao_is_g10']){
      newPayload['eqao_is_g9'] = '1'
    }
    newPayload['test_sessions'] = [];
    newPayload.status = { isOnline: false, assessments: {} };
    return newPayload;
  }

  clientToApiPayloadStudent(fromClient: Partial<IStudentAccount>): any {
    let newPayload: any = { id: 0, first_name: "", last_name: "", roles: [] };

    this.studentMappings.forEach((mapping: { source: string, target: string }) => {
      _.set(newPayload, mapping.source, fromClient[mapping.target]);
    });

    return newPayload;
  }

  clientToApiPayloadOSSLTStudent(fromClient: Partial<IStudentAccount>): any {
    let newPayload: any = { id: 0, first_name: "", last_name: "", roles: [] };

    this.studentMappings.forEach((mapping: { source: string, target: string, key_namespace:string}) => {
      if(overlapVariables.indexOf(mapping.source) > -1){
        _.set(newPayload, mapping.source, fromClient[mapping.target]);
      }else{
        if(mapping.key_namespace == 'eqao_sdc_g10'){
          _.set(newPayload, mapping.source, fromClient["_g10_"+mapping.target]);
        }  
      }  
    });

    return newPayload;
  }

  // generateStudents(len=30){
  //   const mappedList = initMappedList(generateEntries(len, (i) => {
  //     const lang = this.lang.getCurrentLanguage();
  //     const random = genericIdentity(this.lang.tra('sample_student_last_name'), lang);
  //     const courses = STUDENT_G9_COURSES_SIMPLE.list
  //     const classroom = randArrEntry([
  //       {classCode:'MFM1P - AP - 3', course:courses[0].id},
  //       {classCode:'MPM1D - AC - 1', course:courses[1].id},
  //       {classCode:'MPM1D - AC - 2', course:courses[1].id},
  //     ])
  //     return {
  //       id: randInt(1000,9999),
  //       uid: randId(),
  //       email: random.email,
  //       group_id: 0, //randInt(1000,9999),
  //       first_name: random.firstName,
  //       last_name: random.lastName,
  //       displayName: random.name,
  //       eqao_student_gov_id: String(randInt(Math.pow(10, 8), Math.pow(10, 9) - 1)),
  //       test_sessions: [],
  //       lang: randArrEntry(['EN', 'FR']),
  //       eqao_g9_course: <string> classroom.course,
  //       classCode: coinFlip(0.9) ? classroom.classCode : undefined,
  //     }
  //   })) ;
  //  mappedList.list.sort((a, b) => {
  //     const _a = a;
  //     const _b = b;
  //     if (_a.last_name > _b.last_name){ return 1 }
  //     if (_a.last_name < _b.last_name){ return -1 }
  //     if (_a.first_name > _b.first_name){ return 1 }
  //     if (_a.first_name < _b.first_name){ return -1 }
  //     else{ return 0 }
  //   })
  //   return mappedList;
  // }


}
