import { IMenuTabConfig } from "../../../ui-partial/menu-bar/menu-bar.component";

export interface IView<T> extends IMenuTabConfig<T> {
  imgUrl: string,
  secondaryImgUrl?: string;
  description: string,
  hasIndicator?: boolean,
  disabled?: boolean;
}

export enum BCFSADistrictAdminView {
  DASHBOARD = 'dashboard',
  BC_FSA_TECH_READI = 'tech_readiness',
  BC_FSA_ASSESSMENTS = 'assessment_setup1',
  BC_FSA_PROGRESS_REPORTS = 'session_reports',
  BC_FSA_FINAL_RESULTS = 'final_results',
  BC_FSA_SESSION_INFO = 'sessions',
  BC_FSA_SCHOOL_ADMINS = 'school_administrators',
  BC_FSA_STUDENTS = 'students',
  BC_FSA_SCORE_ENTRY = 'score-entry',
}

export const BC_FSA_DISTRICT_ADMIN_VIEWS = [
  {
    id: BCFSADistrictAdminView.BC_FSA_ASSESSMENTS,
    caption: ('da_assessments_bc'), //'Technical Readiness',
    imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/assessments/1610134215488/assessments.svg',
    description: ('da_assessments_tab_desc'),
    disabled: false,
  },
  {
    id: BCFSADistrictAdminView.BC_FSA_SCHOOL_ADMINS,
    caption: ('sa_admin_accounts_bc'), //'Administrator Accounts',
    imgUrl: 'https://bubo.vretta.com/vea/platform/vea-web-client/uploads/f0f329342bb35c6aa020c574fc223b62/Group_15.png',
    description: ('sda_admin_accounts_desc_bc'),
    disabled: false,
  },
  {
    id: BCFSADistrictAdminView.BC_FSA_STUDENTS,
    caption: ('sa_students_bc'), //'Students',
    imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/student_info/1610134278044/student_info.svg',
    description: ('sda_students_desc_bc'),
    disabled: false,
  },
  {
    id: BCFSADistrictAdminView.BC_FSA_PROGRESS_REPORTS,
    caption: ('sa_session_reports_bc'), //'Technical Readiness',
    imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/session_progress_reports/1610134294563/session_progress_reports.svg',
    description: ('da_session_reports_tab_desc'),
    disabled: false,
  },
  // {
  // id:BCFSADistrictadminView.BC_FSA_STUDENTS,
  // caption: ('Students'), //'Class Sections',
  // imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/Students/1606258542435/Students.png',
  // description: ('See this page to review the list of registered students, fill out the Special Materials Request Webform, and more.'), //'You can review and manage the classes as they progress with their introductory materials and administration of the assessment.',
  // disabled: true,
  // },
  // {
  // id:BCFSADistrictadminView.BC_FSA_INVIGILATORS,
  // caption: ('Invigilators'), //'Technical Readiness',
  // imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/teacher/1606258596598/teacher.png',
  // description: ('See this page to create new accounts and manage accounts at your school.'), //'',
  // },
  // disabled: true,
  // {
  // id:BCFSADistrictadminView.BC_FSA_COMPLETION_REPORTS,
  // caption: ('Session Reports'), //'Technical Readiness',
  // imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/Completion Reports/1606258648133/Completion Reports.png',
  // description: ('See this page to monitor the assessment submissions at your school. Ensure that all students registered have written the FSA. You can also view/export the School Level Data Report from this page.'), //'',
  // disabled: true,
  // },
  {
    id: BCFSADistrictAdminView.BC_FSA_SCORE_ENTRY,
    caption: ('sa_score_entry_bc'), //'Teachers',
    imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/Score Entry/1606258686121/Score Entry.png',
    description: ('da_score_entry_tab_desc'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
  },
  {
    id: BCFSADistrictAdminView.BC_FSA_FINAL_RESULTS,
    caption: ('sa_results_reports_bc'), //'Teachers',
    imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/final_results/1610134339358/final_results.svg',
    description: ('da_results_reports_tab_desc'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
    disabled: false,
  },
  // {
  //   id: BCFSADistrictadminView.BC_FSA_CREDENTIALING,
  //   caption: ('Credentialing'), //'Credentialing',
  //   imgUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/session_progress_reports/1610550572361/session_progress_reports.svg',
  //   description: ('Credentialing'),
  //   disabled: true,
  // },
]
