
<div class="clickable-header" [style.min-width.em]="minWidth">
  <button class="button is-small is-white btn-sort" (click)="toggleSort()" tabindex="0">
    <span class="is-header-text" [class.is-wrap-disabled]="disableCaptionWrap" [ngStyle]="{'color': color}">
      <tra [slug]="caption"></tra>
    </span>
    <span class="sorting-indicator" [ngSwitch]="getSortType()">
      <span *ngSwitchCase="SortMode.DESC"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
      <span *ngSwitchCase="SortMode.ASC"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
    </span>
  </button>
  <button *ngIf="!disableFilter" class="button is-small is-light btn-filter" (click)="toggleFilter()" tabindex="0">
    <i class="fa fa-filter" aria-hidden="true"></i>
  </button>
</div>
<div class="filter-settings" *ngIf="isFilterActive">
  <div *ngIf="customFilter;else defaultFilter">
    <custom-column-filter
      [id]="id"
      [filter]="customFilter"
      [ctrl]="ctrl"
    ></custom-column-filter>
  </div>
  <ng-template #defaultFilter>
    <capture-filter-range
      [id]="id"
      [isActive]="true"
      [model]="getFilterModel()"
      (change)="updateFilter()"
    ></capture-filter-range>
  </ng-template>
</div>
