


const enum M {
    REAL_LIFE_N = 'REAL_LIFE_N',
    INFO_P = 'INFO_P',
    NEWS_REP = 'NEWS_REP',
    DIALOGUE = 'DIALOGUE',
    WMC = 'WMC',
    SOP = 'SOP'
}

export const enum EOssltTestType {
    LINEAR = 'LINEAR',
    NORMAL = 'NORMAL'
}

const OSSLT_MODULE_DEFS = {
    'REAL_LIFE_N' : {
        'en': {
            title: 'Real-Life Narrative',
            title_slug: 'lbl_realLife',
            location: 'Woodstock, ON',
            earnedBadge_slug:'lbl_earned_1',
            location_slug: 'lbl_woodstock_loc',
            region: 'Woodstock',
            popUpDesc: 'lbl_woodstock1',
            alt: 'lbl_woodstock_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/38543/authoring/image/1609358444176/image.png',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/badge012-01 2/1607392300081/badge012-01 2.png',
            bottom: '8%',
            right: '25.5%',
            popUpBottom: '12%',
            popUpRight: '22.75%'
        }, 
        'fr': {
            title: 'Récit de vie',
            title_slug: 'lbl_realLife',
            location: 'Shédiac, Nouveau-Brunswick',
            location_slug: 'lbl_woodstock_loc',
            earnedBadge_slug:'lbl_earned_1',
            region: 'Shédiac',
            popUpDesc: 'lbl_woodstock1',
            alt: 'lbl_woodstock_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image7/1607527430895/image7.png',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/hockey_stick/1608567254750/hockey_stick.svg',
            bottom: '20%',
            right: '8.5%',
            popUpBottom: '24%',
            popUpRight: '5.75%'
        }
    },
    'INFO_P' : {
        'en': {
            title: 'Information Paragraph',
            title_slug: 'lbl_InfoParagraph',
            location_slug: 'lbl_toronto_loc',
            location: 'Toronto, ON',
            earnedBadge_slug:'lbl_earned_2',
            region: 'Toronto',
            popUpDesc: "lbl_toronto",
            alt: 'lbl_toronto_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image7/1607384860053/image7.jpg',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/Toronto001-01 2/1607392356465/Toronto001-01 2.png',
            bottom: '10%',
            right: '23%',
            popUpBottom: '14%',
            popUpRight: '20.25%'
        },
        'fr': {
            title: 'Paragraphe informatif',
            title_slug: 'lbl_InfoParagraph',
            location: 'Ottawa, Ontario',
            earnedBadge_slug:'lbl_earned_2',
            location_slug: 'lbl_toronto_loc',
            region: 'Ottawa',
            popUpDesc: "lbl_toronto",
            alt: 'lbl_toronto_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image5/1607527643608/image5.jpg',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/logoottawa_1/1607546535293/logoottawa_1.svg',
            bottom: '12%',
            right: '21%',
            popUpBottom: '16%',
            popUpRight: '18.25%'
        }
    },
    'NEWS_REP' : {
        'en': {                    
            title: 'News Report',
            title_slug: 'lbl_NewsReport',
            location: 'Montreal, QC',
            location_slug: 'lbl_montreal_loc',
            earnedBadge_slug:'lbl_earned_3',
            region: 'Montreal',
            popUpDesc: "lbl_montreal",
            alt: 'lbl_montreal_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image4/1607384923485/image4.jpg',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/QC Montreal-01 2/1607392331253/QC Montreal-01 2.png',
            bottom: '15%',
            right: '18%',
            popUpBottom: '19%',
            popUpRight: '15.25%'
        },
        'fr': {
            title: 'Nouvelle journalistique',
            title_slug: 'lbl_NewsReport',
            location: 'Timmins, Ontario',
            earnedBadge_slug:'lbl_earned_3',
            location_slug: 'lbl_montreal_loc',
            region: 'Timmins',
            popUpDesc: "lbl_montreal",
            alt: 'lbl_montreal_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image8/1607527783620/image8.jpg',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/Timmins__ON/1607627297647/Timmins__ON.svg',
            bottom: '18%',
            right: '28%',
            popUpBottom: '22%',
            popUpRight: '25.25%',
        }
    },
    'DIALOGUE' : {
        'en': {
            title: 'Dialogue',
            title_slug: 'lbl_Dialogue',
            location: 'Dryden, ON',
            location_slug: 'lbl_dryden_loc',
            earnedBadge_slug:'lbl_earned_4',
            region: 'Dryden',
            popUpDesc: "lbl_dryden",
            alt: 'lbl_dryden_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image6/1607384993123/image6.jpg',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/drydenlogo_1/1607546506720/drydenlogo_1.svg',
            bottom: '17%',
            right: '43%',
            popUpBottom: '21%',
            popUpRight: '40.25%',
        },
        'fr': {
            title: 'Dialogue',
            title_slug: 'lbl_Dialogue',
            location: 'Kenora, ON',
            earnedBadge_slug:'lbl_earned_4',
            location_slug: 'lbl_dryden_loc',
            region: 'Kenora',
            popUpDesc: "lbl_dryden",
            alt: 'lbl_dryden_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image6/1607527902696/image6.png',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/Kenora/1607627335117/Kenora.svg',
            bottom: '19%',
            right: '45%',
            popUpBottom: '23%',
            popUpRight: '42.25%',
        }
    },
    'WMC' : {
        'en': {
            title: 'Writing Multiple Choice',
            title_slug: 'lbl_multiple',
            location: 'Kitigan Zibi, QC',
            location_slug: 'lbl_kitigan_loc',
            earnedBadge_slug:'lbl_earned_5',
            region: 'Kitigan Zibi',
            popUpDesc: "lbl_kitigan",
            alt: 'lbl_kitigan_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image3/1607385055587/image3.jpg',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/logoKitiganZibi/1607546404343/logoKitiganZibi.svg',
            complete: false,
            hasFlags: false,
            hasUnfilled: false,
            noReadingSelection: true,
            arrayOfUnfilledQuestions: [],
            arrayOfFlaggedQuestions: [],
            bottom: '15%',
            right: '22%',
            popUpBottom: '19%',
            popUpRight: '19.25%'
        },
        'fr': {
            title: 'Questions à choix multiple en écriture',
            title_slug: 'lbl_multiple',
            location: 'Kitigan Zibi, QC',
            earnedBadge_slug:'lbl_earned_5',
            location_slug: 'lbl_kitigan_loc',
            region: 'Kitigan Zibi',
            popUpDesc: "lbl_kitigan",
            alt: 'lbl_kitigan_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image3/1607385055587/image3.jpg',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/logoKitiganZibi/1607546404343/logoKitiganZibi.svg',
            noReadingSelection: true,
            bottom: '15%',
            right: '22%',
            popUpBottom: '19%',
            popUpRight: '19.25%',
        }
    },
    'SOP' : {
        'en': {
            title: 'Series of Paragraphs',
            title_slug: 'lbl_seriesParagraph',
            location: 'Ottawa, ON',
            location_slug: 'lbl_ottawa_loc',
            earnedBadge_slug:'lbl_earned_6',
            region: 'Ottawa',
            popUpDesc: "lbl_ottawa",
            alt: 'lbl_ottawa_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image/1607462303317/image.png',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/logoottawa_1/1607546535293/logoottawa_1.svg',
            noReadingSelection: true,
            bottom: '12%',
            right: '21%',
            popUpBottom: '16%',
            popUpRight: '18.25%',
        },
        'fr': {
            title: 'Texte d’opinion',
            title_slug: 'lbl_seriesParagraph',
            location: 'Ottawa, ON',
            earnedBadge_slug:'lbl_earned_6',
            location_slug: 'lbl_ottawa_loc',
            region: 'Ottawa',
            popUpDesc: "lbl_ottawa",
            alt: 'lbl_ottawa_alt',
            popUpImg:'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/image/1607462303317/image.png',
            badge: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/building_02/1607700404213/building_02.svg',
            noReadingSelection: true,
            bottom: '12%',
            right: '21%',
            popUpBottom: '16%',
            popUpRight: '18.25%',
        }
    }
}

const OSSLT_ASSESSMENT_MODULE_ORDER = {
    'LINEAR': [
        [
            M.NEWS_REP,
            M.INFO_P,
            M.REAL_LIFE_N  
        ],
        [
            M.WMC,
            M.SOP,
            M.DIALOGUE
        ]
    ],
    'NORMAL': [
        [
            M.REAL_LIFE_N,
            M.INFO_P,
            M.NEWS_REP
        ],
        [
            M.DIALOGUE,
            M.WMC,
            M.SOP
        ]
    ]
} 

export const genOssltModules = (isLinear: boolean, lang: string) => {
    const moduleOrder = OSSLT_ASSESSMENT_MODULE_ORDER[isLinear ? EOssltTestType.LINEAR : EOssltTestType.NORMAL];
    const res = [];
    let id = 1
    for(let session_i = 0; session_i < moduleOrder.length; session_i++) {
        res.push([]);
        for(let module_i = 0; module_i < moduleOrder[session_i].length; module_i++) {
            const module = 
            {
                id,
                ...OSSLT_MODULE_DEFS[moduleOrder[session_i][module_i]][lang],
                complete: false,
                hasFlags: false,
                hasUnfilled: false,
                arrayOfUnfilledQuestions: [],
                arrayOfFlaggedQuestions: []
            }
            res[session_i].push(module);
            id++;
        }
    }
    return res;
}
