<div>
  <div style="overflow:auto;" >
    <table [ngStyle]="getTableStyle()" [style.max-width]="getTableMaxWidth()" [style.border-box]="getTableMaxWidth() == 'none' ? 'content-box' : 'border-box'">
      <tr [class.header-row]="this.element.isFirstRowHeader" *ngIf="getToprowValue() && !element.isTopRowHidden">
        <td *ngIf="!element.isLeftColumnHidden" [class.header-col]="this.element.isFirstColHeader">
          <element-render-text *ngIf="this.element.topLeftText" [element]="element.topLeftText"></element-render-text>
        </td>
        <td *ngFor="let cell of this.element.topRow; let col_i = index;" class="min-col-width selectable-cell" [style.text-align]="cell.alignText ? cell.alignText : 'left'"
          [style.max-width]="this.element.answerColumnWidth && this.element.isAnswerWidthSet ? this.element.answerColumnWidth+'em' : 'none'" [style.background-color]= "cell.defaultColor ? cell.defaultColor :  '#ffffff'">
          <div class="option-container-content"
            [style.font-size]="this.element.fontSize+'em'" 
            [style.display]="'flex'" 
            [style.justify-content]="cell.alignItems ? cell.alignItems : 'flex-start'"
            [style.text-align]="cell.alignText ? cell.alignText : 'left'"
            [style.white-space]="'pre-wrap'"
            [style.max-width.em]="this.element.answerColumnWidth ? this.element.answerColumnWidth : 'none'"
          >
            <div *ngIf="!isAudio(cell)" (mouseover)="hoverOver(cell)">
              <element-render 
                [contentElement]="cell.content"
                [questionPubSub]="questionPubSub"
              ></element-render>
              <render-audio 
                *ngIf="cell.voiceover && cell.voiceover.url"
                [url]="cell.voiceover.url" 
                [trigger]="getHoverTrigger(cell)" 
                [isTriggerDisabled]="!isVoiceOverEnabled()"
              ></render-audio>
            </div>
            <div *ngIf="isAudio(cell) && cell.audio">
              <element-render-audio [element]="cell.audio" [isLocked]="isLocked" [isShowSolution]="isShowSolution" [questionState]="questionState"></element-render-audio>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let cell of this.element.leftCol; let row_i = index;" class="min-col-width selectable-cell">
        <td *ngIf="!element.isLeftColumnHidden" [style.text-align]="cell.alignText ? cell.alignText : 'left'" [class.header-col]="this.element.isFirstColHeader" 
          [style.max-width]="this.element.questionColumnWidth && this.element.isQuestionWidthSet ? this.element.questionColumnWidth+'em' : 'none'">
          <div class="option-container-content"
            [style.font-size]="this.element.fontSize+'em'" 
            [style.display]="'flex'" 
            [style.justify-content]="cell.alignItems ? cell.alignItems : 'flex-start'"
            [style.text-align]="cell.alignText ? cell.alignText : 'left'"
            [style.white-space]="'pre-wrap'"
            [style.max-width.em]="this.element.questionColumnWidth ? this.element.questionColumnWidth : 'none'"
          >
            <div *ngIf="!isAudio(cell)" (mouseover)="hoverOver(cell)">
              <element-render 
                [contentElement]="cell.content"
                [questionPubSub]="questionPubSub"
              ></element-render>  
              <render-audio 
                *ngIf="cell.voiceover && cell.voiceover.url"
                [url]="cell.voiceover.url" 
                [trigger]="getHoverTrigger(cell)" 
                [isTriggerDisabled]="!isVoiceOverEnabled()"
              ></render-audio>
            </div>
            <div *ngIf="isAudio(cell) && cell.audio">
              <element-render-audio [element]="cell.audio" [isLocked]="isLocked" [isShowSolution]="isShowSolution" [questionState]="questionState"></element-render-audio>
            </div>
          </div>
        </td>
        <td 
          *ngFor="let c of this.element.topRow; let col_i = index;" 
          class="clickable-cell"
          (click)="checkOrUnCheckAnswer(row_i, col_i)"
          (keyup.enter)="checkOrUnCheckAnswer(row_i, col_i)"
          [ngStyle]="getCellStyle(row_i,col_i)"
        >
          <div *ngIf="this.testTakerAnswers[row_i] && this.testTakerAnswers[row_i][col_i]">
            <div *ngIf="!element.isElementInSelectableCell">
              <div *ngIf="this.testTakerAnswers[row_i][col_i].value === true">
                <!--<i *ngIf="!element.isRadio" [ngClass]="['fa', 'fa-check-square', 'fa-'+this.element.checkBoxSize+'x']"></i>
                <i *ngIf="element.isRadio" [ngClass]="['fas', 'fa-circle', 'fa-'+this.element.checkBoxSize+'x']"></i>-->
                <span *ngIf="!element.isRadio" [ngClass]="['fa-stack', 'fa-'+this.element.checkBoxSize+'x']">
                  <i [ngClass]="['fas', 'fa-check-square', 'fa-stack-1x']" [style.color]="'white'" tabindex="0"></i>
                  <i [ngClass]="['far', 'fa-check-square','fa-stack-1x' ]" [style.color]="'black'" tabindex="0"></i>  
                </span>
                <span *ngIf="element.isRadio" [ngClass]="['fa-stack', 'fa-'+this.element.checkBoxSize+'x']">
                  <i [ngClass]="['fas', 'fa-circle', 'fa-stack-1x']" [style.color]="'black'" tabindex="0"></i>
                  <i [ngClass]="['far', 'fa-circle','fa-stack-1x' ]" [style.color]="'black'" tabindex="0"></i>  
                </span>
              </div>
              <div *ngIf="!this.testTakerAnswers[row_i][col_i].value">
                <span *ngIf="!element.isRadio" [ngClass]="['fa-stack', 'fa-'+this.element.checkBoxSize+'x']">
                  <i [ngClass]="['fas', 'fa-square', 'fa-stack-1x']" [style.color]="'white'" tabindex="0"></i>
                  <i [ngClass]="['far', 'fa-square','fa-stack-1x' ]" [style.color]="'black'" tabindex="0"></i>  
                </span>
                <span *ngIf="element.isRadio" [ngClass]="['fa-stack', 'fa-'+this.element.checkBoxSize+'x']">
                  <i [ngClass]="['fas', 'fa-circle', 'fa-stack-1x']" [style.color]="'white'" tabindex="0"></i>
                  <i [ngClass]="['far', 'fa-circle','fa-stack-1x' ]" [style.color]="'black'" tabindex="0"></i>  
                </span>
              </div>
            </div>
            <div *ngIf="element.isElementInSelectableCell">
              <div *ngIf="!this.testTakerAnswers[row_i][col_i].value">
                <div *ngIf="this.element.checkBoxRows[row_i][col_i].cellType == 'text'">
                  <element-render-text *ngIf="this.element.checkBoxRows[row_i][col_i].textCellElement" [element]="this.element.checkBoxRows[row_i][col_i].textCellElement"></element-render-text>
                </div>
                <div *ngIf="this.element.checkBoxRows[row_i][col_i].cellType == 'image'">
                  <element-render-image *ngIf="this.element.checkBoxRows[row_i][col_i].imgCellElement" [element]="this.element.checkBoxRows[row_i][col_i].imgCellElement"></element-render-image>
                </div>
                <div *ngIf="this.element.checkBoxRows[row_i][col_i].cellType == 'math'">
                  <element-render-math *ngIf="this.element.checkBoxRows[row_i][col_i].mathCellElement" [element]="this.element.checkBoxRows[row_i][col_i].mathCellElement"></element-render-math>
                </div>
              </div>
              <div *ngIf="this.testTakerAnswers[row_i][col_i].value">
                <div *ngIf="this.element.checkBoxRows[row_i][col_i].selectedCellType == 'text'">
                  <element-render-text *ngIf="this.element.checkBoxRows[row_i][col_i].textCellSelectedElement" [element]="this.element.checkBoxRows[row_i][col_i].textCellSelectedElement"></element-render-text>
                </div>
                <div *ngIf="this.element.checkBoxRows[row_i][col_i].selectedCellType == 'image'">
                  <element-render-image *ngIf="this.element.checkBoxRows[row_i][col_i].imgCellSelectedElement" [element]="this.element.checkBoxRows[row_i][col_i].imgCellSelectedElement"></element-render-image>
                </div>
                <div *ngIf="this.element.checkBoxRows[row_i][col_i].selectedCellType == 'math'">
                  <element-render-math *ngIf="this.element.checkBoxRows[row_i][col_i].mathCellSelectedElement" [element]="this.element.checkBoxRows[row_i][col_i].mathCellSelectedElement"></element-render-math>
                </div>
              </div>
            </div>
          </div>
        </td>
    </tr>
  </table>
</div>
</div>

