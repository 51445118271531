import { IPanelLink } from "./type";
import { AccountType } from "../../../constants/account-types";
import { SchoolAdminView } from "../../../ui-schooladmin/view-schooladmin-dashboard/data/views";
export const schoolAdminPanels:IPanelLink[] = [
    {
        caption: 'lbl_tech_readi', // Class Sections
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${SchoolAdminView.TECH_READI}`,
        iconUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/clipboard/1602235150476/clipboard.png'
        // iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-boosterpacks.png?alt=media&token=2142f93e-c2a5-4eaf-9465-4af843253153'
    },
    {
        caption: 'lbl_classes_groups', // Class Sections
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${SchoolAdminView.CLASSROOMS}`,
        iconUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/school/1602128112754/school.png',
    },
    {
        caption: 'lbl_teachers', // Assessments
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${SchoolAdminView.TEACHERS}`,
        iconUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
    },
    {
        caption: 'g9_students', // Students
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${SchoolAdminView.STUDENTS}`,
        iconUrl: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/students/1602128010278/students.png',
    },
]