<div *ngIf="showInstr()" class="question-block-label mb-3" style="text-align: center; font-size:0.8em;">
  <tra-md [slug]="getInstrSlug()"></tra-md>
</div>

<div
#elPosContainer
class="pos-container"
>
  <div cdkDropListGroup style="display:flex;" [style.flex-direction]="element.isTargetsAbove ? 'column-reverse' : 'column'">

    <div
      style="display:flex;"
      [ngStyle]="getDragWidthSet()">
      <div
        tabindex="0"
        style="display: flex;
        flex-direction: row;
        flex: 1 1 0%;
        align-items: flex-start;
        flex-wrap: wrap;
        border: unset;
        height: 100%;"
        cdkDropList
        class="drag-drop-list drag-drop-source mb-3"
        [class.is-empty]="draggables.length == 0 "
        [cdkDropListData]="draggables"
        cdkDropListSortingDisabled
        (keyup.Enter)="onEnter($event, {}, draggables, draggables.length, true);"
        (mousedown)="onEnter($event, {}, draggables, draggables.length, true);"
        (cdkDropListDropped)="drop($event, true)">
        <div 
          class="drag-el" 
          *ngFor="let elPos of draggables; index as idx" 
          cdkDrag style="margin-right: 10px;"
          [cdkDragDisabled]="isLocked"
          tabindex="0"
          (keyup.Enter)="onEnter($event, elPos, draggables, idx, true);dragStarted(elPos) "
          (mousedown)="onEnter($event, elPos, draggables, idx, true);dragStarted(elPos)"
          [style.max-width.em]="this.element.blankTargetSize"
          [style.white-space]="'nowrap'"
          [class.is-selected]="isSelected(elPos, draggables)"
        > 
          <span [innerHtml]="this.updateRender(elPos.ref.element.caption)"></span>
          <render-audio
            [url]="getElementAudio(elPos.ref.voiceover)"
            [trigger]="getDragTrigger(elPos)"
            [isTriggerDisabled]="!isVoiceoverEnabled()"
          ></render-audio>
          <!--<element-render
            *ngIf="elPos.ref.element"
            [contentElement]="elPos.ref.element"
            [isLocked]="true"
            [style.white-space]="'nowrap'"
            [questionState]="{}"
          ></element-render>-->
        </div>
      </div>
    </div>

    <div class="group-container" [class.mb-3]="this.element.isTargetsAbove" [style.min-height.em]="3">
      <div class="block-el" *ngFor="let block of blocks; index as idx" [ngSwitch]="!!block.isTarget"  [style.min-height.em]="!isDragBetweenWords() ? 1.6 : 0">
        <span *ngSwitchCase="false" [class.is-space-after]="block.isSpaceAfter">
          <markdown-inline [input]="block.str"></markdown-inline>
        </span>
        <div 
          *ngSwitchCase="true" 
          [class.block-drop]="!isDragBetweenWords()" 
          [class.inline-drop]="isDragBetweenWords()" 
          [class.has-no-content]="block.contents.length == 0"
          [class.align-items]="'center'"
        >
          <div
            tabindex="0"
            (keyup.Enter)="onEnter($event, block.contents[0], block.contents, idx, false);"
            (mousedown)="onEnter($event, block.contents[0], block.contents, idx, false);"
            cdkDropList
            [cdkDropListData]="block.contents"
            class="drag-drop-list"
            [style.min-width]="!isDragBetweenWords() && block.contents.length == 0 && this.element.blankTargetSize ? this.element.blankTargetSize+'em' : !isDragBetweenWords() ? '2.5em' : '0em'"
            style="flex-wrap: wrap; flex-direction: row;"
            (cdkDropListDropped)="drop($event, false)"
            [class.is-selected]="isSelected(block.contents[0], block.contents)"
          >
          <div 
              class="drag-el is-placed" 
              [style.margin-bottom.em]="0"
              *ngFor="let assignedElement of block.contents" 
              (mousedown)="onEnter($event, block.contents[0], block.contents, idx, false);"
              cdkDrag 
              [cdkDragDisabled]="isLocked"
              (mousedown)="dragStarted(assignedElement)"
            >
              <element-render
                *ngIf="assignedElement.ref.element"
                [contentElement]="assignedElement.ref.element"
                [isLocked]="true"
                [questionState]="{}"
              ></element-render>
              <render-audio
                [url]="getElementAudio(assignedElement.ref.voiceover)"
                [trigger]="getDragTrigger(assignedElement)"
                [isTriggerDisabled]="!isVoiceoverEnabled()"
              ></render-audio>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--
    <div *ngIf="this.element.isTargetsAbove">
      <div
        style="display: flex;
        flex-direction: row;
        flex: 1 1 0%;
        align-items: flex-start;
        flex-wrap: wrap;
        border: unset;
        height: 100%;"
        cdkDropList
        class="drag-drop-list drag-drop-source mb-3"
        [class.is-empty]="draggables.length == 0 "
        [cdkDropListData]="draggables"
        cdkDropListSortingDisabled
        (cdkDropListDropped)="drop($event, true)">
        <div class="drag-el" *ngFor="let elPos of draggables" 
          cdkDrag style="margin-right: 10px;"
          [cdkDragDisabled]="isLocked"
          (mousedown)="dragStarted(elPos)"
          [style.max-width.em]="this.element.blankTargetSize"
          [style.white-space]="'nowrap'"
        > 
          <span [innerHtml]="this.updateRender(elPos.ref.element.caption)"></span>
          <render-audio
            [url]="getElementAudio(elPos.ref.voiceover)"
            [trigger]="getDragTrigger(elPos)"
            [isTriggerDisabled]="!isVoiceoverEnabled()"
          ></render-audio>
        </div>
      </div>
    </div>-->
  </div>
</div>
