import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { WhitelabelService } from '../domain/whitelabel.service';
import { LangService } from './lang.service';
// import { } from './'

export interface StylingProcess {
  slug: string,
  config?: any //Value gets fed into the corresponding function
}

export interface mcqStyleSub {
  isContentsCenteredVertically: boolean,
  isContentsCentered: boolean,
  isOptionLabelsDisabled: boolean,
  isRadioDisabled: boolean,
  isSelectToggle: boolean
}

export interface mcqStyle {
  grid: mcqStyleSub,
  vertical: mcqStyleSub,
  horizontal: mcqStyleSub
}

export interface ISectionPopup {
  [slug: string] : {
    text : string;
    nextBtnText: string;
    backBtnText: string;
  }
}


export enum EStyleProfile { //For backwards compatibility, slugs named with .json. new slugs can ignore this.
  // DEFAULT = 'defaultstyleprofile.json',
  BC = 'defaultstyleprofile.json',
  FSA_NUM = 'fsa-num-styleprofile.json',
  FSA_LIT = 'fsa-lit-styleprofile.json',
  GRAD_NUM = 'grad-num-styleprofile.json',
  GRAD_LIT_EN = 'grad-lit-en-styleprofile.json',
  GRAD_LIT_FR = 'grad-lit-fr-styleprofile.json',
  OSSLT = 'osslt-styleprofile.json',
  GR9 = 'gr9styleprofile.json',
  TEST = 'TEST'
}

export interface StyleProfileOption {
  id: EStyleProfile,
  caption: string
}

export const styleProfileOptions :StyleProfileOption[] = [
  {id: EStyleProfile.BC, caption: 'style_profile_bc'},
  {id: EStyleProfile.FSA_NUM, caption: 'style_profile_fsa_num'},
  {id: EStyleProfile.FSA_LIT, caption: 'style_profile_fsa_lit'},
  {id: EStyleProfile.GRAD_NUM, caption: 'style_profile_grad_num'},
  {id: EStyleProfile.GRAD_LIT_EN, caption: 'style_profile_grad_lit_en'},
  {id: EStyleProfile.GRAD_LIT_FR, caption: 'style_profile_grad_lit_fr'},
  {id: EStyleProfile.OSSLT, caption: 'style_profile_osslt'},
  {id: EStyleProfile.GR9, caption: 'style_profile_gr9'},
  // {id: EStyleProfile.TEST, caption: 'Test'} For testing if needed
]

//defines the behaviour for voice script generation and text styling
export interface IStyleProfile {
  [langCode:string]: {
    voiceScript: {
      general: {
        removeBeginningEllipses: boolean,
        blank: TranslationSlug
        first: TranslationSlug
        second: TranslationSlug
        third: TranslationSlug
        last: TranslationSlug
        or: TranslationSlug
      }
      plainText:StylingProcess[],
      math: StylingProcess[],
      table: {
        beginTable: TranslationSlug,
        beginTableValues: TranslationSlug,
        beginRow: TranslationSlug,
        beginColumn: TranslationSlug,
        endTable: TranslationSlug,
        endTableOfValues: TranslationSlug,
        headerProcesses: StylingProcess[],
        onlyReadHeaderCells: boolean,
        columnHeaderReadRowsFirst: boolean
      }
      select_table: {
        beginTable: TranslationSlug,
        beginRow: TranslationSlug,
        beginColumn: TranslationSlug,
      }
      insertion: {
        insertion: TranslationSlug,
        terms: TranslationSlug,
        text: TranslationSlug,
        instr_blocks: TranslationSlug,
        instr_blind: TranslationSlug,
      }
      grouping: {
        grouping_instr: TranslationSlug,
        blocks: TranslationSlug,
        targets: TranslationSlug,
      }
      ordering: {
        order_instr: TranslationSlug,
        items: TranslationSlug,
        targets: TranslationSlug,
        box: TranslationSlug
      }
      input: {
        rem_words: TranslationSlug,
        total_words: TranslationSlug,
      }
      mcq: {
        beginOption: TranslationSlug,
        beginOptions: TranslationSlug,
        dropDown: TranslationSlug,
        dropDown_blank: TranslationSlug
      }
      advancedInline: {
        pauseAroundExpression: boolean
      }
    },
    renderStyling: {
      plainText: {
        transforms: StylingProcess[]
      },
      math: {
        transforms: StylingProcess[]
      }, 
      mcq: {
        showSelectAll: boolean,
        multiSelectCheckbox: boolean
      }
    },
    configuration: {
      textInput: {
        byWord: boolean,
        extraWordsHardLimit?: number;
      },
      math: {
        smartFence: boolean
      },
      mcqSettings: {
        defaults: mcqStyle[];
      },
      testRunner: {
        canReturnToSections: boolean; 
        zoomToFitScreen: boolean;
        sectionPopup: ISectionPopup;
      }
    }
  }
}

type TranslationSlug = string;

const regex_bold = /\*\*(\S(.*?\S)??)\*\*/gi;
const regex_em = /\*(\S(.*?\S)??)\*/gi;
const regex_del = /\~\~(\S(.*?\S)??)\~\~/gi;
const regex_variable = /\`\w\`/gi;
const regex_latex_to_speech_var = /\'[A-Za-z]\'/g;

const regex_pos_decimal_number = /\d+(([ \.\,]|(&nbsp;))\d+)*([\.\,]\\d+)?/g;
//Define these regexes using strings so that build optimization doesn't compromise them.
const regex_number = new RegExp("\\$?Y?[\\-\\−]?\\d([\\d\\ \\,\\+\\=]|(&nbsp;))*([\\.\\,]\\d+)?((\\ |(&nbsp;))?\\$)?\\°?", "g"); // the extra Y in front is to escape years in French 
// const regex_katex_number_or_units = new RegExp("(\\$?Y?[\\-\\−]?\\d([\\d\\ \\,:\\+\\=]|(&nbsp;))*([\\.\\,]\\d+)?(\\ |(&nbsp;))?)?[\\$¢]?((\\°[CF]?)|[℃℉])?", "g");
const regex_katex_number = new RegExp("\\$?Y?[\\-\\−]?\\d+(([ \\.\\,]|(&nbsp;))\\d+)*([\\.\\,]\\d+)?", "g");
const regex_katex_units = new RegExp("([\\$¢]|((\\°[CF]?)|[℃℉]))", "g");
const regex_katex_number_with_units = new RegExp(regex_katex_number.source + "(( |(&nbsp;))?" + regex_katex_units.source + ")?", "g");
const regex_katex_operators = new RegExp("(( |(&nbsp;))?([:\\+=×÷>≠≤≥\\-−]|(<(?![a-zA-Z/]))))", "g");
//Allow matching period or comma at the end of the expression / number so it does not break
const regex_katex_expression = new RegExp(regex_katex_number_with_units.source + "(" + regex_katex_operators.source + "(( |(&nbsp;))?" + regex_katex_number_with_units.source + ")?)*","g"); 
const regex_katex_expression_or_lone_unit = new RegExp("((" + regex_katex_expression.source + ")|(" + regex_katex_units.source + "))", "g");

const regex_exponent = /\^((\d|[a-zA-Z])+)/;
const regex_unit_w_exponent = new RegExp("(?:mm)|(?:cm)|(?:km)|(?:dam)|(?:m)", "g");
const regex_exponent_global = new RegExp(regex_exponent, 'g');
const regex_subscript =/\_((\d|[a-zA-Z])+)/g;
const regex_number_ord = /\d+[a-zA-Z]+/g;

const regex_coordinates = new RegExp("\\(\\s*" + regex_number.source + "\\s*\\,\\s*" + regex_number.source + "\\s*\\)", "g");
const regex_regular_or_latex_coordinates = new RegExp("(?:(?:\\()|(?:\\\\left\\())\\s*" + regex_number.source + "\\s*\\,\\s*" + regex_number.source + "\\s*(?:(?:\\))|(?:\\\\right\\)))","g"); 
const regex_hyphen_word_pair = /([a-zA-Z]+([–\-]| [–\-] )[a-zA-Z]+)|([–\-][a-zA-Z]+)/g; //Second case is since we can have things of the form `y`-intercept which this will now solve as well.

@Injectable({
  providedIn: 'root'
})

export class StyleprofileService {
  constructor(
    private lang: LangService,
    private auth: AuthService,
    private routes: RoutesService,
    private whitelabel: WhitelabelService
  ) {
    //this.setStyleProfile(EStyleProfile.BC);
    let defaultStyleProfile = EStyleProfile.GR9;
    if(this.whitelabel.getSiteFlag('IS_BCED')) {
      defaultStyleProfile = EStyleProfile.BC;
    }
    this.setStyleProfile(defaultStyleProfile);
  }
  
  private styleProfileId: number;
  private selectedStyleProfile: EStyleProfile;
  private styleProfile:IStyleProfile;
  public styleProfileSub: BehaviorSubject<boolean> = new BehaviorSubject(false)

  getStyleProfile() : IStyleProfile {
    return this.styleProfile
  }

  getStyleProfileChanges() : BehaviorSubject<boolean> {
    return this.styleProfileSub;
  }

  setStyleProfile(slug: EStyleProfile) {
    if(this.selectedStyleProfile === slug) {
      return;
    }

    this.auth.apiGet(this.routes.TEST_AUTH_STYLE_PROFILES, 1, {query: {slug}}).then( res => {
      if(!res || !res.length) {
        return;
      }
      this.styleProfileId = res[0].id
      this.styleProfile = JSON.parse(res[0].config);
      this.selectedStyleProfile = slug;
      this.styleProfileSub.next(true);
    })
    /*
    this.styleProfileName = filename;
    this.styleProfile.next(require(`../../../data/${filename}`));
    */
  }

  getStyleProfileId() { return this.styleProfileId }

  getSectionPopup = (popupSlug, lastSection:boolean = false) => {
    if(!popupSlug && !lastSection) popupSlug = 'default';
    const testRunnerConf = this.styleProfile[this.lang.c()].configuration.testRunner;
    if(!testRunnerConf?.sectionPopup) return undefined;
    return testRunnerConf.sectionPopup[popupSlug];
  }

  getSectionPopupSlugs = () => {
    const testRunnerConf = this.styleProfile[this.lang.c()].configuration.testRunner;
    if(!testRunnerConf?.sectionPopup) return [];
    return Object.keys(testRunnerConf.sectionPopup).map(slug => slug);
  }

  getSelectedStyleProfile() {
    return this.selectedStyleProfile;
  }

  getTextInputLimit() : boolean {
    return this.getStyleProfile()[this.lang.c()].configuration.textInput.byWord;
  }

  getCanChangeAfterSubmit() : boolean {
    const config = this.getStyleProfile()[this.lang.c()].configuration
    if (config.testRunner?.canReturnToSections){
      return true;
    }
    return false;
  }

  getExtraWordsOverLimit() : number {
    return this.getStyleProfile()[this.lang.c()].configuration.textInput.extraWordsHardLimit;
  }

  getDefaultMcqStyles() {
    const profile = this.getStyleProfile()[this.lang.c()];
    if (profile && profile.configuration && profile.configuration.mcqSettings)
      return this.getStyleProfile()[this.lang.c()].configuration.mcqSettings.defaults;
    return undefined;
  }

  // to do: these should be loaded from the DB
  // getProfileOptions() {
  //   return [
  //     // "defaultstyleprofile.json", 
  //     // "gr9styleprofile.json", 
  //     // "mpt-styleprofile.json", 
  //     // "osslt-styleprofile.json", 
  //     "bc-styleprofile.json",
  //     "fsa-num-styleprofile.json",
  //     "fsa-lit-styleprofile.json",
  //     "grad-num-styleprofile.json",
  //     "grad-lit-en-styleprofile.json",
  //     "grad-lit-fr-styleprofile.json",
  //   ]; // ,
  // }

  getProfileOptions() {
    return styleProfileOptions;
  }
}

  const stripMarkedupEdges = (count:number, str:string) => {
    return str.substr(count, str.length-count*2)
  }

  const stripBoldedMarkup = (str: string) => {
    return str.replace(regex_bold, (str) => stripMarkedupEdges(2, str) );
  }
  
  const stripItalicizedMarkup = (str: string) => {
    return str.replace(regex_em, (str) => stripMarkedupEdges(1, str));
  }
  
  const stripStrikethroughMarkup = (str: string) => {
    return str.replace(regex_del, (str) => stripMarkedupEdges(2, str));
  }
  
  const stripVariableMarkup = (str: string) => {
    return str.replace(regex_variable, (str) => `"${stripMarkedupEdges(1,str)}"`);
  }
  
  const processEmbeddedRatios = (str: string, lang:string) => {
    let delimiter = ':'
    if(lang === 'en'){
      delimiter = ' ... to ... '
    }
    if(lang === 'fr'){
      delimiter = ' ... à ... '
    }

    const regex_ratio = new RegExp(regex_pos_decimal_number.source + "((:|( : ))" + regex_pos_decimal_number.source + ")+", "g");
    str = str.replace(regex_ratio, (ratio:string) =>{
      return ' ... ' + ratio.split(':').join(delimiter);
    })
    return str;
  }

  export const processFrenchDecimal = (str: string, comma: string = ",") => {
    return transformFrenchDecimal(str, 'fr', comma);
  }
  
  const readIllionDollars = (str:string, lang:string) => {
    const millions = /\$ ?\d*\.+\d+\smillion/g;
    const billions = /\$ ?\d*\.+\d+\sbillion/g;
    const trillions = /\$ ?\d*\.+\d+\strillion/g;
    const decimalize = (num)=>{
      const index = num.indexOf('.');
      let beforeDecimal = num.substr(0,index).substr(1);
      let afterDecimal = num.substr(index+1);
      let magnitude = afterDecimal.substr(afterDecimal.indexOf(' ')+1)
      afterDecimal = afterDecimal.substr(0,afterDecimal.indexOf(' '));
      let suffix = '';
      for (let i = 0;i<afterDecimal.length;i++) {
        suffix += afterDecimal[i]+' ';
      }
      suffix+=magnitude;
      suffix+=" dollars";
      const splitNum = beforeDecimal + ' point ' + suffix;
      return splitNum;
    }

    str = str.replace(millions, (match)=>{
      return decimalize(match);
    });

    str = str.replace(billions, (match)=>{
      return decimalize(match);
    })

    str = str.replace(trillions, (match)=>{
      return decimalize(match);
    })

    return str;
  }

  const readDollars = (str:string, lang:string, isAnglicizedNum:boolean=false) => {
    str = readIllionDollars(str, lang);
    let identify;
    let render;
    if (lang === 'en'){
      identify = /\$ ?\d*\.+\d+/g;
      render = (match) => {
        const num = match.substr(1, match.length-1).trim();
        const pieces = num.split('.');
        let phrase = '';
        const cents = +pieces[1]; //remove leading 0s
        if (pieces[0] !== '0'){
          phrase = '$'+pieces[0];
          if(cents !== 0) {
            phrase += ' and ' ;
          }
        }
        
        if(cents !== 0) {
          phrase = phrase + cents + ' cent' + (cents === 1 ? "" : "s");
        }

        return phrase;
      };
    }
    if (lang === 'fr'){
      identify = new RegExp("\\d+[\\,\\.]\\d+ ?\\$", "g");
      render = (match) => {
        if (isAnglicizedNum){
          match = match.split('.').join(',')
        }
        const num = match.substr(0, match.length-1).trim();
        const pieces = num.split(',');
        let phrase = '';
        const cents = +pieces[1]; //remove leading 0s
        if (pieces[0] !== '0'){
          phrase = pieces[0] + '$';
          if(cents !== 0) {
            phrase += ' et ' ;
          }
        }
        
        if(cents !== 0) {
          phrase = phrase + cents + ' cent' + (cents === 1 ? "" : "s");
        }

        return phrase;
      };
    }
    return str.replace(identify, render)
  }

  const readCents = (str:string) => {
    const identify = new RegExp("(\\d+) ?\\¢"); //declaring it like this prevents build optimization from compromising it

    //Do non-globally to make use of RegExp.$1 properly
    while(identify.test(str)) {
      str = str.replace(identify, (match) => {
        const quantity = +RegExp.$1; //remove leading zeroes
        const unit = `cent${quantity === 1 ? "" : "s"}`
        return `${quantity} ${unit}`    //cents is the same in both english and french
      });
    }
    return str;
  } 
  
  const processEmbeddedNumbers = (str:string, lang:string) => {
    if(lang === 'en'){
      const regex_number = /\d*(\.\d+)?/g;
      str = str.replace(regex_number, (num:string) =>{
        const split = num.split('.');
        if (split.length > 1){
          const whole = split[0];
          const decimal = ` "${split[1].split('').join(`" "`)}"`;
          return whole + ' point ' + decimal;
        }
        else{
          return num;
        }
      });
    }
    if(lang === 'fr'){
      const regex_number = /\d*(\,\d+)?/g;
      str = str.replace(regex_number, (num:string) =>{
        const split = num.split(',');
        if (split.length > 1){
          const whole = split[0];
          const decimal = ` ${split[1].split('').join(` `)}`;
          return whole + ' virgule ' + decimal;
        }
        else{
          return num;
        }
      });
    }
    return str;
  }
  
  const processEmbeddedVariables = (str:string, lang:string) => {
    return str.replace(/\"y\"/gi, str => `"igrec"`);
  }
  
  export const applyReplacement = (str:string, knownReplacements:string[][], appends?:string[]) => {
    if (!appends){
      appends = [''];
    }
    appends.forEach(append => {
      knownReplacements.forEach(replacement => {
        str = str.split(replacement[0]+append).join(replacement[1]+append);
      });
    })
    return str;
  }
  
  //--Math
  
  const handleMixedFractions = (latex:string, delim:string) => {
    const regex_mixed = /\d\\frac/g;
    return latex.replace(regex_mixed, match => {
      const i = match.indexOf('\\frac');
      const pre = match.substr(0, i);
      const post = match.substr(i, match.length-1);
      return `${pre}\\text{ ${delim}}${post}`
    });
  }
  
  const handleExponentsEn = (str:string) => {
    str = str.replace(/to the ("[A-Za-z]"|\d+(?:\.\d+)?) power/g, 'to the $1');
    str = str.replace(/to the/g, ' to the power of');
    str = str.replace(/raised/g, '');
    str = str.replace(/\w+ power/g, match => {
      const split = match.split(' power')
      if (split[0] === 'the'){ return match; }
      return split[0];
    });
    return str;
  }
  
  const handleLatexFractions = (str: string, lang:string) => {
    return str.replace(/the fraction [\d, ]+? over [\d, ]+?\. End fraction/g, match => {
      return applyReplacement(match, [
        ['the fraction', ''],
        [', over', lang === 'en' ? ' over' : ' sur']
      ]);
    });
  }
  
  const handleComplexLatexFractions = (str: string, lang:string) => {
    return str.replace(/the fraction [\w\+,. ]+?over [\w\+,. ]+?\. End fraction/g, match => {
      return applyReplacement(match, [
        ['the fraction', lang === 'en' ? 'the fraction whose numerator is' : 'fraction dont le numérateur est'],
        ['over', lang === 'en' ? 'and whose denominator is' : 'et le dénominateur est']
      ]);
    });
  }

  const handleComplexLatexFractionsAllOver = (str: string, lang:string) => {
    return str.replace(/the fraction .+?over .+?\. End fraction/g, match => {
      return applyReplacement(match, [
        ['the fraction', ''],
        [', over', ` ... ${lang === 'en' ? 'all over' : 'sur'} ... `]
      ]);
    });
  }
  
  const latexToSpeakableText = (latex:string) => {
    const SPACE = ' ';
    const MathLive = (window as any).MathLive;
    const UNIQUE_LATEX_INSERTION = '++++'
    const UNIQUE_LATEX_INSERTION_MATCH = 'plus plus plus plus'
    
    latex = latex.replace(/text\{/g, 'text\{'+SPACE); 
    latex = latex.replace(/textrm\{/g, 'text\{'+SPACE);
    latex = latex.replace(/operatorname\{/g, 'text\{'+SPACE); // if we want units formatted with this function to be read out loud
    latex = latex.replace(/sqrt{\d*[\.+\d]+}/g, match => { // to force a "End of square root"
      return match.substr(0, match.length-1) + UNIQUE_LATEX_INSERTION + '}'
    });
    let str = MathLive.latexToSpeakableText(latex)+SPACE;
    str = str.replace(RegExp(UNIQUE_LATEX_INSERTION_MATCH, 'g'), '');
    str = str.replace(/ldots/g, '...'); 

    //Make sure there is space around the ellipses
    str = str.replace(/(\S)\.\.\./g, "$1 ...");
    str = str.replace(/\.\.\.(?=\S)/g, "... ");

    return str;
  }
  
  const applyMathReplacement = (str:string) => {
    //Commented this out for now because I'm not sure what it is achieving. 
    //It removes decimals from the beginning of the number, but we want those there for voice script generation (for gr. 9 at least, and I don't see why not for MPT). 
    //If no problems arise after 2 months or so, we can remove it entirely. (Oct. 6, 2020)

    // for (let i=0; i<str.length; i++){
      //   str = str.trim();
      //   if (str.substr(0, 1) === '.'){
        //     str = str.substr(1, str.length-1);
        //   }
        // }
    const quotationMark = /'(.*?)'/g
    return str.replace(quotationMark, match => {
      return `"${match.substr(1, match.length-2)}"`
    });
  }
  
  const procesAutosSup = (str:string, lang:string) => {
    const i_letters = str.search(/[a-zA-Z]+/);
    const number = str.substr(0, i_letters)
    let letters = str.substr(i_letters, str.length-1);
    let allowedSup = [];
    if (lang === 'en'){ 
      allowedSup = [
        'st',
        'nd',
        'rd',
        'th',
      ]
    }
    if (lang === 'fr'){
      allowedSup = [
        'e',
        'er',
        're',
        'ée',
        'ee',
        'ieme',
        'ième',
      ]
    }
    if (allowedSup.indexOf(letters) !== -1){
      letters = '<sup>'+letters+'</sup>'
    }
    return number + letters
  }

  const processBoldedMarkup = (str:string) => {
    return str.replace(regex_bold, (match) => '<strong>' + stripMarkedupEdges(2, match) + '</strong>');
  }
  
  const processItalicsMarkup = (str:string) => {
    return str.replace(regex_em, (match) => '<em>' + stripMarkedupEdges(1, match) + '</em>');
  }
  
  const processStrikethroughMarkup = (str:string) => {
    return str.replace(regex_del, (match) => '<del>' + stripMarkedupEdges(2, match) + '</del>');
  }
  
  const processMetres = (str: string) => {
    return str.replace(/m[2-3]\b/g, (match) => {
      const letter = match.substr(0, 1);
      const number = match.substr(1, 1);
      return `${letter}<sup>${number}</sup>`;
    })
  }

  const processOrdinal = (str: string, lang: string) => {
    return str.replace(regex_number_ord, match => procesAutosSup(match, lang));
  }
  
  const processExponent = (str: string) => {
    return str.replace(regex_exponent_global, (match) => {
      return '<sup>' + match.substr(1, match.length -1) + '</sup>'
    });
  }
  
  const processQuotes = (str: string, lang: string) => {
    let regex = /\"[^"]*\"/g
    if (lang=='fr') regex = /«([^«»])*»/g

    return str.replace(regex, (match)=>{
      return "... "+match+" ...";
    });
  }

  const processSubscript = (str: string) => {
    return str.replace(regex_subscript, (match) => {
      return '<sub>' + match.substr(1, match.length -1) + '</sub>'
    });
  }
  
  const processKatexNumberFormatting = (str: string, processes: StylingProcess[], matchExpression:boolean, additionalClass:string = "") => {
    const regex = matchExpression ? regex_katex_expression_or_lone_unit : regex_number;
    return str.replace(regex, (match) => {
      if (match === '.' || !match || match === ''){
        return match;
      }
      else{
        if(additionalClass !== "") {
          additionalClass = " " + additionalClass;
        }
        return `<span class="katex${additionalClass}">` + formatNumber(match, processes) + '</span>'
      }
    });
  }
  
  const processKatexVariableFormatting = (str: string) => {
    // console.log("PROCESS VARIABLE");
    return str.replace(regex_variable, (match) => '<em><span class="katex">' + stripMarkedupEdges(1, match) + '</span></em>');
  }

  const formatNumber = (str:string, processes:StylingProcess[]) => {
    return processText(str, processes);
  }

  const moveDegreesLeftAndMakeBigger = (str: string) => {
    return str.replace(/°/g, "<span class=\"move-degree-left-make-bigger\">°</span>");
  }

  const processTooltip = (str: string) => {
    const tooltipRegex = /<a tooltip=\"[^"]*\".*?<\/a>/g;
    const tooltipTextRegex = /tooltip=\"[^"]*\"/g;
    const linkRegex = /<a.*<\/a>/g;
    const alignRegex = /align=\"[^"]*\"/g;
    let matchedStrs = str.match(tooltipRegex);
    if (matchedStrs) {
      matchedStrs.map(matchedStr => {
        const matchedTooltipTexts = matchedStr.match(tooltipTextRegex);
        if (!matchedTooltipTexts) return;
        let [ tooltipText ] = matchedTooltipTexts;
        tooltipText = tooltipText.replace('tooltip="', '');
        tooltipText = tooltipText.substring(0, tooltipText.length - 1);
        const link = matchedStr.match(linkRegex);
        const matchedAligns = matchedStr.match(alignRegex);
        let alignment = '';
        if (matchedAligns) {
          const [matchedAlign] = matchedAligns;
          alignment = matchedAlign;
        }
        const replaceWithStr = 
          `<div class="tooltip"><div class="tooltip-text" ${alignment} role="tooltip">${tooltipText}</div>${link}</div>`
        const updatedStr = matchedStr.replace(tooltipRegex, replaceWithStr);
        str = str.replace(matchedStr, updatedStr);
      });
    }
    return str;
  }

  const processBookmark = (str: string) => {
    const bookmarkRegex = /<(bookmark*)[^>]*>([^]*?)<\/\1>/g;
    const bookmarkIdRegex = /id=\"[^"]*\"/g;
    const bookmarkOpenTagRegex = /<bookmark [^>]+>/g;
    const bookmarkCloseTagRegex = /<\/bookmark>/g;
    const highlightModeRegex = /highlightmode=\"[^"]*\"/g;
    let matchedStrs = str.match(bookmarkRegex);
    if (matchedStrs) {
      matchedStrs.map(matchedStr => {
        const bookmarkIds = matchedStr.match(bookmarkIdRegex);
        if (!bookmarkIds) return;
        let [ bookmarkId ] = bookmarkIds;
        bookmarkId = bookmarkId.replace('id="', '');
        bookmarkId = bookmarkId.substring(0, bookmarkId.length - 1);
        const matchedHighlightModes = matchedStr.match(highlightModeRegex);
        let highlightMode = '';
        if (matchedHighlightModes) {
          const [matchedHighlightMode] = matchedHighlightModes;
          highlightMode = matchedHighlightMode.replace('highlightmode="', '').substring(0, matchedHighlightMode.length - 1);
        }
        let text = matchedStr.replace(bookmarkOpenTagRegex, '').replace(bookmarkCloseTagRegex, '');
        const replaceWithStr = 
          `<div class="bookmark id-${bookmarkId} ${highlightMode}">${text}</div>`;
        const updatedStr = matchedStr.replace(bookmarkRegex, replaceWithStr);
        str = str.replace(matchedStr, updatedStr);
      });
    }
    return str;
  }

  const transformUnitsToInUnits = (str:string, lang:string, replacements:string[][]) => {
    let word = (lang === 'en') ? "in" : "en";

    replacements.forEach((replacement)=> {
      const replace = replacement[0];
      const replace_with = replacement[1];

      const escapedReplace = escapeRegEx(replace);
      const replace_regex = new RegExp(`\\(\\s?${escapedReplace}\\s?\\)`);
      str = str.replace(replace_regex, ` ... ${word} ${replace_with}`);
    });

    return str;
  }

  const insertPauseBetweenSimpleLatexFractionAndVar = (str: string) => {
    const latexFractionRegEx = /[\d,\.\s]+? over [\d,\.\s]+?/g;
    const fractionAndVar = new RegExp("(" + latexFractionRegEx.source + ")\\s*(" + regex_latex_to_speech_var.source + ")");

    const latexFractionRegExFr = /[\d,\.\s]+? sur [\d,\.\s]+?/g;
    const fractionAndVarFr = new RegExp("(" + latexFractionRegExFr.source + ")\\s*(" + regex_latex_to_speech_var.source + ")");

    str = str.replace(fractionAndVar, '$1 ... $2')
    return str.replace(fractionAndVarFr, '$1 ... $2');
  }

  const insertPauseBetweenComplexLatexFractionAndVar = (str: string) => {
    const latexFractionRegEx = /the fraction .+? over .+? End fraction\./g;
    const fractionAndVar = new RegExp("(" + latexFractionRegEx.source + ")\\s*(" + regex_latex_to_speech_var.source + ")");
    return str.replace(fractionAndVar, '$1 ... $2');
  }

  const processCoordinates = (str: string) => {
    return str.replace(regex_coordinates, (match) => {
      return stripMarkedupEdges(1, match).split(",").join(" ... ");
    });
  }

  const processCoordinateList = (str: string, lang: string) => {
    const delim = lang === 'en' ? "and" : "et";
    const regex_coordinates_list = new RegExp(regex_regular_or_latex_coordinates.source + "( *(\\,)? *(" + delim + ")? *" +regex_regular_or_latex_coordinates.source + ")*", 'g');
    
    return str.replace(regex_coordinates_list, (match) => {
      //use non-capturing (?:) parentheses to prevent them from being included in the split (Which they otherwise are)
      const coordinateListSplitRegEx= new RegExp("(?:(?:\\))|(?:\\\\right\\))) *(?:\\,)? *(?:" + delim + ")? *(?:(?:\\()|(?:\\\\left\\())",'g');
      let splitOnCoordinates = match.split(coordinateListSplitRegEx);
      match = splitOnCoordinates.join(`) ... ${delim} ... (`);

      match = match.replace(/\\left\(/g, "(");
      return match.replace(/\\right\)/g, ")");
    });
  }

  const processPlaintextExponent = (str: string, lang: string) => {
    while(regex_exponent.test(str)) {
      //Do non-globally to make use of RegExp.$1 properly
      str = str.replace(regex_exponent, (match) => {
        const number = RegExp.$1;
        if(lang === 'en') {
          if(number == "2") {
            return " squared";
          } else if(number == "3") {
            return " cubed";
          } else {
            return ` to the power of ${number}`;
          }
        } else {
          return ` exposant ${number}`;
        }
      });
    }
 
    return str;
  }

  const convertBlankToPause = (str: string) => {
    return str.replace(/_(_)+/g, " ... ");
  }

  export const removeLeadingEllipses = (str: string) => {
    const ellipse_regex = /\.\.+/g;
    const beginning_ellipses = new RegExp("^((\\s*" + ellipse_regex.source + "\\s*)+)");
    return str.replace(beginning_ellipses, (match) => {
      return match.replace(RegExp.$1, ""); //remove any leading ellipses since Polly says "point" when they are read
    });
  }

  const transformSubscriptToSpeech = (str: string, lang: string) => {
    const prepend = (lang === 'fr' ? "...": "subscript");

    return str.replace(regex_subscript, (match) => {
      const subscript = match.substr(1);
      const subscriptLetters = subscript.split("");
      const quotedLetters = subscriptLetters.map( (value, index, arr) => {
        if(isNaN(+value)) {
          return `\"${value}\"`
        } else {
          return value;
        }
      });
      return ` ${prepend} ${quotedLetters.join(" ... ")}`;
    });
    // while(regex_subscript.test(str)) {
    //   str = str.replace(regex_subscript, (match) => {
    //       return ` ${prepend} ${RegExp.$1}`;
    //   });
    // }
    // return str;
  }

  const transformUnderscoresToBlank = (str: string) => {
    const underscore_regex = /_(_)+/g
    return str.replace(underscore_regex, (match) => {
      //Replace with fullwidth underscore character (Looks shorter here for some reason, but it works)
      return `<u>${match.replace(/_/g, "&nbsp;&nbsp;")}</u>`;
    });
  }

  const transformUnderscoresToBlankSpace = (str: string, lang:string) => {
    const underscore_regex = /__+([\s,.\?\!])/g
    let blankSpace = " ... Blank Space ... ";
    if (lang=="fr") blankSpace = " ... Espace à remplir ... ";
    str = str.replace(underscore_regex, blankSpace+"$1");
    const underscore_end = /__+$/g
    str = str.replace(underscore_end, blankSpace)
    return str;
  }

  const degreeToDegrees = (str: string, lang: string, latex: boolean) => {
    const degree_regex = latex ? /degree(?!s)/g : /°/g
    
    str = str.replace(degree_regex, (!latex ? " " : "") + (lang === 'fr' ? "degrés" : "degrees")); //Convert all "degree" to "degrees"

    const one_degree_regex =  lang === 'en' ? /(^|\s)1\s+degrees/g : /(^|\s)1\s+degrés/g //Convert the ones back
    str = str.replace(one_degree_regex, (match) => {
      if(lang === 'en') {
        return match.replace("degrees", "degree");
      } else { 
        return match.replace("degrés", "degré");
      }
    });

    return str;
  }

  const processNonBreakingFrenchQuote = (str: string) => {
    const regex_french_quote = /(«\s+\S)|(\S\s+»)/g;
    return str.replace(regex_french_quote, (match) => {
      return match.replace(/ /g, "&nbsp;");
    })
  }
  
  const processNonBreakingHyphenPair = (str: string) => {
    return str.replace(regex_hyphen_word_pair, (match) => {
      match = match.replace(/ /g, "&nbsp;");
      match = match.replace(/[–\-]/g, (hyphenMatch) => {return `&NoBreak;${hyphenMatch}&NoBreak;`;});
      return match;
    });
  }

  export const transformFrenchDecimal = (str:string, lang:string, comma:string=',') => {
    if (lang === 'fr'){
      const pattern = /\d*\.\d+/g;
      return str.replace(pattern, match => {
        return match.split('.').join(comma)
      })
    }
    return str;
  }
  
  export const transformThousandsSeparator = (str:string, lang:string, spacer:string=' ') => {
    let triggerLen = 4;
    if (lang == 'fr'){
      triggerLen = 3;
    }
    return applyThousandsSpacesPY(str, spacer, triggerLen)
  }
  export const applyThousandsSpacesPY = (str:string, spacer:string=' ', triggerLen:number=3) => {
    const DECIMAL_POINT = '.'; // this algorithm assumes that the FR decimal transformation has not yet occurred
    const pattern = /Y?\d*\.*\d+/g;
    return str.replace(pattern, match => {
      if (match[0] === 'Y'){ // year marker
        return match.substr(1, match.length-1);
      }
      const parts = match.split(DECIMAL_POINT)
      const wholePart = parts[0];
      if (wholePart.length > triggerLen){
        const newWholePart = applyThousandsSpaces(wholePart, spacer);
        parts[0] = newWholePart;
      }
      return parts.join(DECIMAL_POINT)
    })
  }
  export const applyThousandsSpaces = (str:string, spacer:string=' ') => {
    var len = str.length;
    var newStr = ''
    for (let i=0; i<len; i++){
      if ((i % 3 === 0) && (i !== 0)){ newStr = spacer + newStr; }
      const index = len - (i+1)
      newStr = str[index] + newStr;
    }
    return newStr;
  }


  const removeLatexFontSeries = (str: string) => {
    return str.replace('\\fontseries{n}', '');
  }

  const removeLatexPlaceholder = (str: string) => {
    str = str.replace('\\placeholder{denominator}', '');
    str = str.replace('\\placeholder{numerator}', '');
    return str.replace('\\placeholder{}', '');
  }

  const transformLatexColonSpacing = (str: string) => {
    const pattern = /:[a-zA-Z0-9\:]+/g;
    return str.replace(pattern, match => {
      return match.split(':').join('{:}')
    })
  }

  const transformLatexDegreeSpacing = (str: string) => {
    // return str.replace(/\\degree (?![CF])/g, "\\kern -0.12em \\degree ");
    return str.replace(/\\degree /g, "\\textsf{\\raisebox{-0.2em}{\\large \\kern -0.02em \\degree }}");
  }

  const transformLatexBracketSpacing = (str: string) => {
    const left_paren_regex = new RegExp( "\\\\left\\(", "g");
    const right_paren_regex = new RegExp("\\\\right\\)", "g");
    str = str.replace(left_paren_regex, "(");
    return str.replace(right_paren_regex, ")");
  }

  const processUnits = (str: string, replacements:string[][], lang: string) => {
    const unitRegexBeginSingular = "[0-9,\s]*([^\\.,\\d]|^)1(?:\\s)?(\\$)?(?:/(?:\\s)?)?";
    const unitRegexBegin = "[0-9,\s]*([\\s\\$]|^)(/(\\s)?)?";
    const unitRegexBeginNumber = "[0-9,\s](\\d\\$?(/(\\s)?))?"
    const unitRegexEnd = "(?=(?:[\\s\\.\\,\\\",\\',\\?\\:\\;\\!\\-\\”\\’\\/]|$))";

    replacements.forEach((replacement) => {
      const singularUnitRegex = new RegExp(unitRegexBeginSingular + escapeRegEx(replacement[0]) + unitRegexEnd);

      while(singularUnitRegex.test(str)) {
        str = str.replace(singularUnitRegex, (match) => {
          let match_removed = "";
          if(RegExp.$1) {
            match_removed = match[0];
            match = match.substr(1); //remove leading character that makes it a valid singular quantity if it exists
          }
          const splitIndex = 1 + (match[1] === " " ? 1 : 0) + (RegExp.$2 === "$" ? 1 : 0) ;
          const unit = makeUnitSingular(replacement[1], lang);
          const match_substring = match.substr(splitIndex); //Remove the digit, space, and $ if they exist
          match_removed += match.substr(0, splitIndex);
          return match_removed + processUnitHelper(match_substring, unit, lang, match_removed[match_removed.length - 1] === " " ? "": " ");
        });
      }
      
      const unitRegexNoSpace = new RegExp(unitRegexBeginNumber + escapeRegEx(replacement[0]) + unitRegexEnd, "g");
      str = str.replace(unitRegexNoSpace, (match) => {
        let splitIndex = 1;
        if(match[1] === "$") {
          splitIndex = 2;
        }
        const match_removed = match.substr(0,splitIndex);
        const match_substring = match.substr(splitIndex);
        return match_removed + processUnitHelper(match_substring, replacement[1], lang, " ");
      });

      const unitRegEx = new RegExp(unitRegexBegin + escapeRegEx(replacement[0]) + unitRegexEnd, "g");
      str = str.replace(unitRegEx, (match) => {
        let processMatch = match;
        let match_removed = "";

        for (let i = 0;i<match.length;i++) {
          if(/[\s\$]/.test(match[i])) {
            processMatch = match.substr(i+1);
            match_removed = match.substr(0,i);
          }
        }
        return match_removed + processUnitHelper(processMatch, replacement[1], lang, match_removed[match_removed.length - 1] === " " ? "": " ");
      });
    });

    return str;
  }

  const processUnitHelper = (match: string, unit: string, lang: string, prefix: string = "") => {
    if(match[0] === "/") {
      return prefix + (lang === 'en' ? "per" : "par") + " " + makeUnitSingular(unit, lang);
    }
    return prefix + unit;
  }

  const transformCelsiusFahrenheitCharacter = (str: string) => {
    const celsius_regex = new RegExp("°C" ,"g");
    const fahrenheit_regex = new RegExp("°F", "g");

    str = str.replace(celsius_regex, "℃");
    return str.replace(fahrenheit_regex, "℉");
  }

  const processNonBreakingNumberWithUnits = (str: string, units: string[]) => {
    units.forEach((unit) => {
      const numberWithUnitRegex = new RegExp(regex_katex_number_with_units.source + "( |(&nbsp;))*" + escapeRegEx(unit) + "(?=([ \\]\\}\\)\\.\\,\\:\\?\\!\\\"\\n]|$))", 'g');
      str = str.replace(numberWithUnitRegex, (match) => {
        return `<span class="no-wrap-on-whitespace">${match}</span>`;
      });
    });

    return str;
  }

  const processNonBreakingUnitsWithExponents = (str: string) => {
    const regexExponentUnit = new RegExp( "\\b(" + regex_unit_w_exponent.source + ")" + "((\\^?[23])|[²³])\\b", "g");
    return str.replace(regexExponentUnit, (match) => {
      return `<span class="no-wrap-on-whitespace">${match}</span>`;
    });
  }

  const handleNonBreakingFrenchTime = (str: string) => {
    const frenchHourRegex = new RegExp("\\d+ h \\d+", "g");
    return str.replace(frenchHourRegex, (match) => {
      return `<span class="no-wrap-on-whitespace">${match}</span>`;
    });
  }

  const replaceCurlyApostrophes = (str: string) => {
    return str.replace(/’/g, "'");
  }

  const processMathRenderUnits = (latex: string) => {
    const possible_units = /((mm)|(cm)|(km)|(dam)|(ml)|(mL)|(mg)|(kg))/g
    const operatorname_regex = new RegExp("\\\\operatorname{" + possible_units.source + "}", "g");
    latex = latex.replace(operatorname_regex, "$1");
    latex = latex.replace(possible_units, "\\operatorname{$1}");
    return latex;
  }

  const processSemicolonSpaceFr = (str: string) => {
    //Since negative lookbehind is not supported by some browsers, need to use a workaround
    const regex_semicolon_html_element = /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/gi //only matches html elements with semicolons at the end
    //Find HTML elements like &nbsp; or &mdash; convert to something unlikely to appear while retaining info about the code
    str = str.replace(regex_semicolon_html_element, '{[({[($1)]})]}}' );
    str = str.replace(';', '&nbsp;;');
    //Convert back to the html element
    str = str.replace(/\{\[\(\{\[\(([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6})\)\]\}\)\]\}\}/gi,'&$1;');
    return str;
  }

  const stylingProcesses = {
    STRIP_BOLDED_MARKUP: stripBoldedMarkup,
    STRIP_ITALICIZED_MARKUP: stripItalicizedMarkup,
    STRIP_STRIKETHROUGH_MARKUP: stripStrikethroughMarkup,
    STRIP_VARIABLE_MARKUP: stripVariableMarkup,
    PROCESS_EMBEDDED_RATIOS: processEmbeddedRatios,
    PROCESS_FRENCH_DECIMAL: processFrenchDecimal,
    READ_MONEY: readDollars,
    READ_CENTS: readCents,
    PROCESS_EMBEDDED_NUMBERS: processEmbeddedNumbers,
    PROCESS_EMBEDDED_VARIABLES: processEmbeddedVariables,
    APPLY_REPLACEMENT: applyReplacement,
    HANDLE_MIXED_FRACTIONS: handleMixedFractions,
    HANDLE_EXPONENTS_EN: handleExponentsEn,
    HANDLE_LATEX_FRACTIONS: handleLatexFractions,
    HANDLE_COMPLEX_LATEX_FRACTIONS: handleComplexLatexFractions,
    HANDLE_COMPLEX_LATEX_FRACTIONS_ALL_OVER: handleComplexLatexFractionsAllOver,
    LATEX_TO_SPEAKABLE_TEXT: latexToSpeakableText,
    APPLY_MATH_REPLACEMENT: applyMathReplacement,
    APPLY_THOUSANDS_SPACES_PY: applyThousandsSpacesPY,
    PROCESS_BOLDED_MARKUP: processBoldedMarkup,
    PROCESS_ITALICS_MARKUP: processItalicsMarkup,
    PROCESS_STRIKETHROUGH_MARKUP: processStrikethroughMarkup,
    PROCESS_METRES: processMetres,
    PROCESS_ORDINAL: processOrdinal,
    PROCESS_EXPONENT: processExponent,
    PROCESS_QUOTES: processQuotes,
    PROCESS_SUBSCRIPT: processSubscript,
    PROCESS_KATEX_NUMBER_FORMATTING: processKatexNumberFormatting,
    PROCESS_KATEX_VARIABLE_FORMATTING: processKatexVariableFormatting,
    TRANSFORM_THOUSANDS_SEPARATOR: transformThousandsSeparator,
    TRANSFORM_UNITS_TO_IN_UNITS: transformUnitsToInUnits,
    INSERT_PAUSE_BETWEEN_SIMPLE_LATEX_FRACTION_AND_VAR: insertPauseBetweenSimpleLatexFractionAndVar,
    INSERT_PAUSE_BETWEEN_COMPLEX_LATEX_FRACTION_AND_VAR: insertPauseBetweenComplexLatexFractionAndVar,
    PROCESS_COORDINATE_LIST: processCoordinateList,
    PROCESS_COORDINATES: processCoordinates,
    PROCESS_PLAINTEXT_EXPONENT: processPlaintextExponent,
    CONVERT_BLANK_TO_PAUSE: convertBlankToPause,
    TRANSFORM_SUBSCRIPT_TO_SPEECH: transformSubscriptToSpeech,
    TRANSFORM_UNDERSCORES_TO_BLANK: transformUnderscoresToBlank,
    TRANSFORM_UNDERSCORES_TO_BLANK_SPACE: transformUnderscoresToBlankSpace,
    DEGREE_TO_DEGREES: degreeToDegrees,
    PROCESS_NON_BREAKING_FRENCH_QUOTE: processNonBreakingFrenchQuote,
    PROCESS_NON_BREAKING_HYPHEN_PAIR: processNonBreakingHyphenPair,
    REMOVE_LATEX_FONT_SERIES: removeLatexFontSeries,
    REMOVE_LATEX_PLACEHOLDER: removeLatexPlaceholder,
    TRANSFORM_LATEX_COLON_SPACING: transformLatexColonSpacing,
    TRANSFORM_LATEX_DEGREE_SPACING: transformLatexDegreeSpacing,
    TRANSFORM_LATEX_BRACKET_SPACING: transformLatexBracketSpacing,
    PROCESS_UNITS: processUnits,
    MOVE_DEGREES_LEFT_AND_MAKE_BIGGER: moveDegreesLeftAndMakeBigger,
    TRANSFORM_CELSIUS_FAHRENHEIT_CHARACTER: transformCelsiusFahrenheitCharacter,
    PROCESS_NON_BREAKING_NUMBER_WITH_UNITS: processNonBreakingNumberWithUnits,
    HANDLE_NON_BREAKING_FRENCH_TIME: handleNonBreakingFrenchTime,
    REPLACE_CURLY_APOSTROPHES: replaceCurlyApostrophes,
    PROCESS_NON_BREAKING_UNITS_WITH_EXPONENTS: processNonBreakingUnitsWithExponents,
    PROCESS_MATH_RENDER_UNITS: processMathRenderUnits,
    PROCESS_TOOLTIP: processTooltip,
    PROCESS_BOOKMARK: processBookmark,
    PROCESS_SEMICOLON_SPACE_FR: processSemicolonSpaceFr
  };

  const makeUnitSingular = (str: string, lang: string) => {

    const perRegex = lang === 'en' ? /(\S+)(?= per)/ : /(\S+)(?= par)/

    if(perRegex.test(str)) {
      return str.replace(perRegex, (match) => {
        if(match[match.length - 1] === 's') {
          match = match.substr(0, match.length -1);
        }
        return match;
      });
    }

    const regex = lang === 'en' ? /(\S+)( (squared|cubed))?/ : /(\S+)( (carrés|cubes))?/;
    return str.replace(regex, () => {
      let unit = RegExp.$1;
      let modifier = RegExp.$2;
      if(unit[unit.length - 1] === 's') {
        unit = unit.substr(0, unit.length -1);
      }

      if(lang === 'fr' && modifier !== "" && modifier[modifier.length - 1] === 's') {
        modifier = modifier.substr(0, modifier.length -1);
      }
      return `${unit}${modifier}`;
    });
  }

  export const escapeRegEx = (str: string) => {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  export const processText = (str: string, processes: StylingProcess[]) => {
    if (str == undefined) return;
    processes.forEach((processDef:StylingProcess) => {
      if(Array.isArray(processDef.config)) {
        str = stylingProcesses[processDef.slug](str, ...processDef.config);
      } else {
        str = stylingProcesses[processDef.slug](str);
      }
      //console.log(processDef.slug);
      //console.log(str);
    });
    return str;
  }


