import { getElementWeight, QuestionState, ScoringTypes } from "../../../ui-testrunner/models";
import { QuestionPubSub } from "../../../ui-testrunner/question-runner/pubsub/question-pubsub";
import { TextToSpeechService } from "../../text-to-speech.service";
import { IContentElementCustomInteraction } from "../model";
import * as PIXI from 'pixi.js';

export abstract class CustomInteractionCtrl {

    abstract element: IContentElementCustomInteraction;
    questionState: QuestionState;
    questionPubSub: QuestionPubSub;
    render;
    addGraphic;
    zoom: number;
    isLocked: boolean;
    textToSpeech: TextToSpeechService
    constructor(questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, textToSpeech: TextToSpeechService){
      this.questionState = questionState;
      this.questionPubSub = questionPubSub;
      this.addGraphic = addGraphic;
      this.render = render;
      this.zoom = zoom;
      this.textToSpeech = textToSpeech
      if (!this.zoom) {
        this.zoom = 1;
      }
      this.isLocked = isLocked
    }

    getScaledMousePos(data) {
      data.global.x/= this.zoom;
      data.global.y/= this.zoom;
      return data.global;
    }

    abstract getUpdatedState() : any;
    updateState() : void {
      this.questionState[this.element.entryId] = 
      { ... this.getUpdatedState(), 
        weight: getElementWeight(this.element),
        scoring_type: ScoringTypes.AUTO
      };
    }
    abstract handleNewState() : void;
    abstract loadAssets(): Promise<PIXI.Loader>;
    
    getText(text:string, style, resolution:number, x, y) {
      const textObj = new PIXI.Text(text, style)
      if (resolution) {
        textObj.resolution = resolution
      }
      if (x) {
        textObj.x = x
      }
      if (y) {
        textObj.y = y
      }
      return textObj
    }

    isHCMode(){
      return this.textToSpeech.isHiContrast;
    }

    getColor() {
      return this.isHCMode() ? 0xffffff : 0x000000;
    }
  }