import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../api/auth.service';
import { AccountType } from '../../constants/account-types';
import { QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { UrlLoaderService } from '../url-loader.service';
import { IContentElementSolution } from './model';
import { TextToSpeechService } from '../text-to-speech.service';


@Component({
  selector: 'element-render-solution',
  templateUrl: './element-render-solution.component.html',
  styleUrls: ['./element-render-solution.component.scss']
})
export class ElementRenderSolutionComponent implements OnInit {

  @Input() element:IContentElementSolution;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(private auth: AuthService,
        private urlLoader: UrlLoaderService,
        private text2Speech: TextToSpeechService
    ) { }

  ngOnInit() {
    
  }

  isVisible() {
    if(this.element.hideFromTestTakers) {
      try {
        return this.isLocked && this.auth.user().value.accountType !== AccountType.TEST_TAKER; //TODO: make invisible to test-takers (including preview as test-taker). for purposes of demo/review leave as is, but we should also hide when previewing as test-taker
      }
      catch (e){
        return false;
      }
    }
    return this.isLocked;
  }

  getTextStyle(){
    let style = {}
    if (this.text2Speech.isHiContrast) {
      style["filter"]="invert(1)";
    }
    return style;
  }

  sanitizeUrl(url:string){
    return this.urlLoader.sanitize(url);
  }
}
